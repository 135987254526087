//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import ClassRef from "./ClassRef";

/**
 * @class
 */
class PluginMelodyClassRef extends ClassRef
{
  static defaultProps = {
    name: "Plugin.Melody",
    type: "class",
    contents: [
      {
        type: "text",
        content: "音楽地図のメロディを扱うプラグインクラスです。"
      },
      {
        type: "table",
        titleAs: "h3",
        title: "Constructor",
        subtitle: "1st arg options of the constructor",
        content: {
          headers: [
            {
              style: { width: "10%" },
              content: "名前"
            },
            {
              style: { width: "10%" },
              content: "型"
            },
            {
              style: { width: "10%" },
              content: "初期値"
            },
            {
              style: { width: "70%" },
              content: "説明"
            }
          ],
          bodies: [
            {
              cells: [
                {
                  content: (
                    <code>revisionId</code>
                  )
                },
                {
                  content: "number"
                },
                {
                  content: "0"
                },
                {
                  content: "使用する音楽地図のリビジョン番号です。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>offset</code>
                  )
                },
                {
                  content: "number"
                },
                {
                  content: "0"
                },
                {
                  content: "イベントのオフセット値（ミリ秒）です。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>pollingInterval</code>
                  )
                },
                {
                  content: "number"
                },
                {
                  content: "50"
                },
                {
                  content: "イベントのポーリング値（ミリ秒）です。"
                }
              ]
            }
          ]
        }
      },
      {
        type: "code",
        content: `
          player.addPlugin(new Songle.Plugin.Melody());

          player.on("melodyEnter",
            function(ev) {
              // do someting ...
            });
        `
      },
      {
        title: "ピッチ（音高）取り扱いについて",
        type: "note",
        content: (
          <p>
            このプラグインを利用して、ピッチ（音高）を取得することはできません。ここで扱うことのできるメロディとは、メロディの発音タイミングや音符・休符の判別フラグのみとなります。
          </p>
        )
      },
      {
        title: "リビジョンについて",
        type: "note",
        content: (
          <p>
            リビジョンとは、音楽理解技術による自動解析の誤りを人間が訂正した際に残る編集履歴のことです。この番号を指定することで、アプリケーションで使用する音楽地図の訂正状態を固定することができます。これは、音楽地図が第三者により編集された場合に発生する不整合を防ぐ場合に役立ちます。リビジョン番号は、<a href="//songle.jp/songs/610565" target="_">楽曲ページ</a>の<a href="//songle.jp/songs/610565/history" target="_">編集履歴</a>より確認することができます。
          </p>
        )
      },
      {
        title: "オフセットについて",
        type: "note",
        content: (
          <p>
            オフセットでは、イベントの実行タイミングにオフセットを与えることができます。これは、アプリケーションの処理負荷などの要因によって、イベントの実行タイミングが前後にズレる場合に役立ちます。また、オフセットはイベント毎に個別に与えることもできます。
          </p>
        )
      },
      {
        type: "code",
        content: `
          player.addPlugin(new Songle.Plugin.Melody({
            offset: -100,
          });

          player.on("melodyEnter",
            function(ev) {
              // this event will be run faster 100ms than the original melody event
            });

          player.on("melodyEnter",
            function(ev) {
              // this event will be run faster 200ms than the original melody event
            }, { offset: -100 });

          player.on("melodyEnter",
            function(ev) {
              // this event will be run faster 300ms than the original melody event
            }, { offset: -200 });
        `
      }
    ],
    constants: [
      {
        name: "MelodyEnterTrapName",
        type: "string",
        contents: [
          {
            type: "text",
            content: "メロディの開始で呼び出されるイベント名の定数です。"
          }
        ]
      },
      {
        name: "MelodyLeaveTrapName",
        type: "string",
        contents: [
          {
            type: "text",
            content: "メロディの終了で呼び出されるイベント名の定数です。"
          }
        ]
      }
    ],
    properties: [
      {
        name: "offset",
        type: "number",
        contents: [
          {
            type: "text",
            content: "イベントのオフセット値（ミリ秒）です。"
          }
        ]
      },
      {
        name: "pollingInterval",
        type: "number",
        contents: [
          {
            type: "text",
            content: "イベントのポーリング値（ミリ秒）です。"
          }
        ]
      }
    ],
    getterProperties: [
      {
        name: "revisionId",
        type: "number",
        contents: [
          {
            type: "text",
            content: "使用する音楽地図のリビジョン番号です。"
          }
        ]
      }
    ],
    events: [
      {
        name: "melodyEnter",
        contents: [
          {
            type: "text",
            content: "メロディの開始で呼び出されるイベントです。"
          },
          {
            type: "code",
            content: `
              player.on("melodyEnter",
                function(ev) {
                  console.log(ev);

                  /**
                  {
                    data: {
                      melody: Melody
                    }
                  }
                  */

                  /**
                  // メロディ構造
                  type Melody = {
                    index: number,           // インデックス
                    startTime: number,       // メロディの開始位置（ミリ秒）
                    startTimeSec: number,    // メロディの開始位置（秒）
                    endTime: number,         // メロディの終了位置（ミリ秒）
                    endTimeSec: number,      // メロディの終了位置（秒）
                    durationTime: number,    // メロディの再生時間（ミリ秒）
                    durationTimeSec: number, // メロディの再生時間（秒）
                    isNote: boolean,         // 音符の判別フラグ
                    isRest: boolean,         // 休符の判別フラグ
                    prev: Melody,            // 前のメロディ構造の参照
                    next: Melody             // 次のメロディ構造の参照
                  }
                  */
                });
            `
          }
        ]
      },
      {
        name: "melodyLeave",
        contents: [
          {
            type: "text",
            content: "メロディの終了で呼び出されるイベントです。"
          },
          {
            type: "code",
            content: `
              player.on("melodyLeave",
                function(ev) {
                  console.log(ev);

                  /**
                  {
                    data: {
                      melody: Melody
                    }
                  }
                  */

                  /**
                  // メロディ構造
                  type Melody = {
                    index: number,           // インデックス
                    startTime: number,       // メロディの開始位置（ミリ秒）
                    startTimeSec: number,    // メロディの開始位置（秒）
                    endTime: number,         // メロディの終了位置（ミリ秒）
                    endTimeSec: number,      // メロディの終了位置（秒）
                    durationTime: number,    // メロディの再生時間（ミリ秒）
                    durationTimeSec: number, // メロディの再生時間（秒）
                    isNote: boolean,         // 音符の判別フラグ
                    isRest: boolean,         // 休符の判別フラグ
                    prev: Melody,            // 前のメロディ構造の参照
                    next: Melody             // 次のメロディ構造の参照
                  }
                  */
                });
            `
          }
        ]
      }
    ]
  };
}

const modules = PluginMelodyClassRef;

export default modules;
