//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Button, Grid, Icon, Image } from "semantic-ui-react";

import ArticleTemplate from "../../../App/components/AppPage/ArticleTemplate";

import ImgTutorialStyle1 from "../../../../../assets/document_module/overview/tutorial-style1";
import ImgTutorialStyle2 from "../../../../../assets/document_module/overview/tutorial-style2";
import ImgTutorialStyle3 from "../../../../../assets/document_module/overview/tutorial-style3";
import ImgTutorialStyle4 from "../../../../../assets/document_module/overview/tutorial-style4";

/**
 * @class
 */
class HowToUse extends ArticleTemplate
{
  static defaultProps = {
    articles: [
      {
        type: "text",
        anchor: "section3",
        titleAs: "h2",
        titleEn: "How to use",
        titleJa: "使い方",
        contentEn: (
          <div>
            <p>
              Songle Sync utilizes automatic music analysis and enables multimedia performances synchronized with a musical piece of your choice from more than a million musical pieces on the web.
              With the following interactive tutorial, you will experience a live demonstration of how Songle Sync works.
            </p>
            <p>
              While Songle Sync provides <abbr title="Application Programming Interface">API</abbr> with which programmers can easily play web-native songs (e.g. YouTube videos and SoundCloud music) and control numerous devices in synchrony with the songs, the first half of this tutorial will not require any prior knowledge&mdash;anyone can enjoy the basic features of Songle Sync!
            </p>
          </div>
        ),
        contentJa: (
          <p>
            本システムを利用することで、ウェブ上で公開されている１５０万曲以上の楽曲を自動解析した結果を使って、楽曲の中身（拍子やサビなど）に連動した演出を作ることができます。チュートリアルサイトでは、大規模音楽連動制御を実際に体験することができます。次のリンク先から、ウェブ上で公開されている楽曲を選んで再生すると、音楽理解技術で事前に自動解析された拍子やサビなどに応じて変化するＣＧアニメーション（演出）を楽しむことができます。
          </p>
        )
      },
      {
        type: "text",
        contentEn: (
          <div>
            <Grid columns="equal">
              <Grid.Column>
                <Image
                  size="huge"
                  src={ImgTutorialStyle1} />
              </Grid.Column>
              <Grid.Column>
                <Image
                  size="huge"
                  src={ImgTutorialStyle2} />
              </Grid.Column>
              <Grid.Column>
                <Image
                  size="huge"
                  src={ImgTutorialStyle3} />
              </Grid.Column>
              <Grid.Column>
                <Image
                  size="huge"
                  src={ImgTutorialStyle4} />
              </Grid.Column>
            </Grid>
            <Grid style={{ marginTop: "24px" }}>
              <Grid.Column textAlign="center">
                <Button
                  color="pink"
                  size="large"
                  as="a"
                  href="//tutorial.songle.jp/sync?lang=en"
                  target="_">
                  <Icon name="linkify" />
                  Start the Songle Sync tutorial
                </Button>
              </Grid.Column>
            </Grid>
          </div>
        ),
        contentJa: (
          <div>
            <Grid columns="equal">
              <Grid.Column>
                <Image
                  size="huge"
                  src={ImgTutorialStyle1} />
              </Grid.Column>
              <Grid.Column>
                <Image
                  size="huge"
                  src={ImgTutorialStyle2} />
              </Grid.Column>
              <Grid.Column>
                <Image
                  size="huge"
                  src={ImgTutorialStyle3} />
              </Grid.Column>
              <Grid.Column>
                <Image
                  size="huge"
                  src={ImgTutorialStyle4} />
              </Grid.Column>
            </Grid>
            <Grid style={{ marginTop: "24px" }}>
              <Grid.Column textAlign="center">
                <Button
                  color="pink"
                  size="large"
                  as="a"
                  href="//tutorial.songle.jp/sync?lang=ja"
                  target="_">
                  <Icon name="linkify" />
                  大規模音楽連動を体験してみる
                </Button>
              </Grid.Column>
            </Grid>
          </div>
        )
      },
      {
        type: "text",
        contentEn: (
          <p>
          </p>
        ),
        contentJa: (
          <p>
            この演出を別の端末と連動させる場合、表示されたＱＲコードをスマートフォンなどの端末から読み込むことで、同じ楽曲と演出が同じタイミングで表示されることを確認することができます。この手順を繰り返していくことで、連動していく機器を次々と増やしていくことが可能です。
          </p>
        )
      },
      {
        type: "text",
        contentEn: (
          <p>
            If a musical piece has not yet been analyzed, you can visit Songle and analyze it and enjoy the multimedia performance with Songle Sync.
            A musical piece playing with Songle Sync is always streaming from its source website (e.g., YouTube), so it cannot be played any more if it gets deleted from the source website.
          </p>
        ),
        contentJa: (
          <p>
            希望の楽曲が自動解析されていない場合は、<a href="//songle.jp" target="_">能動的音楽鑑賞サービスSongle（ソングル）</a>に楽曲を登録することで、再生できるようになります。楽曲は、配信元のウェブサイト上から直接ストリーミング再生される仕組みなので、配信元の楽曲にアクセスできなくなった場合は、再生することはできなくなります。
          </p>
        )
      },
      {
        type: "text",
        titleAs: "h3",
        titleEn: null,
        titleJa: "クイックスタート",
        contentEn: null,
        contentJa: (
          <div>
            <p>
              次のクイックスタートでは、プログラミング言語 JavaScript を対象とした大規模音楽連動制御のプログラミング方法について紹介します。この技術を実際にアプリケーションに組み込みたい場合は、こちらを参照してください。
            </p>
            <Grid style={{ marginTop: "24px" }}>
              <Grid.Column textAlign="center">
                <Button
                  color="pink"
                  size="large"
                  as="a"
                  href="/quickstart/javascript#section3">
                  <Icon name="linkify" />
                  Songle JavaScript API クイックスタート
                </Button>
              </Grid.Column>
            </Grid>
          </div>
        )
      }
    ]
  }
}

const modules = HowToUse;

export default modules;
