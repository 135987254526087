//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import ClassRef from "./ClassRef";

/**
 * @class
 */
class SonglePlayerClassRef extends ClassRef
{
  static defaultProps = {
    name: "Player",
    type: "class",
    contents: [
      {
        type: "text",
        content: "音楽プレイヤーを抽象化したクラスです。"
      },
      {
        type: "table",
        titleAs: "h3",
        title: "Constructor",
        subtitle: "1st arg options of the constructor",
        content: {
          headers: [
            {
              style: { width: "10%" },
              content: "名前"
            },
            {
              style: { width: "10%" },
              content: "型"
            },
            {
              style: { width: "10%" },
              content: "初期値"
            },
            {
              style: { width: "70%" },
              content: "説明"
            }
          ],
          bodies: [
            {
              cells: [
                {
                  content: (
                    <code>accessToken</code>
                  )
                },
                {
                  content: "string"
                },
                {
                  content: "null"
                },
                {
                  content: (
                    <p>
                      <a href="#Plugin.SongleSync">Songle Sync API (Plugin.SongleSync)</a> を利用する際に使用する公開用のトークンを指定します。<br/>
                      トークンは、ユーザページより発行することができます。
                    </p>
                  )
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>secretToken</code>
                  )
                },
                {
                  content: "string"
                },
                {
                  content: "null"
                },
                {
                  content: (
                    <p>
                      <a href="#Plugin.SongleSync">Songle Sync API (Plugin.SongleSync)</a> を利用する際に使用する認証用のトークンを指定します。<br/>
                      トークンは、ユーザページより発行することができます。
                    </p>
                  )
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>mediaElement</code>
                  )
                },
                {
                  content: "HTMLElement | string"
                },
                {
                  content: "document.body"
                },
                {
                  content: "外部埋め込みプレーヤーを埋め込む先の DOM を指定します。"
                }
              ]
            }
          ]
        }
      },
      {
        type: "code",
        content: `
          const player =
            new Songle.Player({
              mediaElement: "#songle"
            });
        `
      }
    ],
    constants: [
      {
        name: "MinVolume",
        type: "number",
        contents: [
          {
            type: "text",
            content: "設定可能な最小音量です。"
          }
        ]
      },
      {
        name: "MaxVolume",
        type: "number",
        contents: [
          {
            type: "text",
            content: "設定可能な最大音量です。"
          }
        ]
      }
    ],
    properties: [
      {
        name: "accessToken",
        type: "string",
        contents: [
          {
            type: "text",
            content: " Songle Sync API の公開用のトークンです。"
          }
        ]
      },
      {
        name: "secretToken",
        type: "string",
        contents: [
          {
            type: "text",
            content: " Songle Sync API の認証用のトークンです。"
          }
        ]
      },
      {
        name: "mediaElement",
        type: "HTMLElement | string",
        contents: [
          {
            type: "text",
            content: "メディアを埋め込む先の DOM です。"

          }
        ]
      },
      {
        name: "volume",
        type: "number",
        contents: [
          {
            type: "text",
            content: (
              <p>
                音量（パーセンテージ）です。
              </p>
            )
          },
          {
            type: "code",
            content: `
              console.log(player.volume);

              player.volume += 10; // + 10 %

              player.volume -= 10; // - 10 %
            `
          }
        ]
      }
    ],
    getterProperties: [
      {
        name: "media",
        type: "Songle.Media.SuperClass",
        contents: [
          {
            type: "text",
            content: "読み込まれているメディアです。"
          }
        ]
      },
      {
        name: "musicMap",
        type: "any",
        contents: [
          {
            type: "text",
            content: "読み込まれている音楽地図です。"
          },
          {
            type: "code",
            content: `
              console.log(player.musicMap);

              /**
              {
                beat: any
                chord: any
                melody: any
                chorus: any
                variation: any
              }
              */
            `
          }
        ]
      },
      {
        name: "isReady",
        type: "boolean",
        contents: [
          {
            type: "text",
            content: "アクティベート状態です。"
          }
        ]
      },
      {
        name: "isPlaying",
        type: "boolean",
        contents: [
          {
            type: "text",
            content: "再生状態です。"
          }
        ]
      },
      {
        name: "isPaused",
        type: "boolean",
        contents: [
          {
            type: "text",
            content: "停止状態です。"
          }
        ]
      },
      {
        name: "isSeeking",
        type: "boolean",
        contents: [
          {
            type: "text",
            content: "シーク状態です。"
          }
        ]
      },
      {
        name: "duration",
        type: "number",
        contents: [
          {
            type: "text",
            content: "メディアの再生時間（ミリ秒）です。"
          }
        ]
      },
      {
        name: "position",
        type: "number",
        contents: [
          {
            type: "text",
            content: "メディアの再生位置（ミリ秒）です。"
          }
        ]
      }
    ],
    methods: [
      {
        name: "addPlugin",
        type: "void",
        contents: [
          {
            type: "text",
            content: "プラグインクラスをインストールします。"
          },
          {
            type: "note",
            title: "プラグインについて",
            content: (
              <p>
                このメソッドを利用することで <a href="#Player">Player</a> クラスの機能を必要に応じて拡張していくことができます。<br/>
                例えば、音楽の拍子に連動させたい場合は <a href="#Plugin.Beat"><code>Plugin.Beat</code></a> をインストールすることで、拍子に連動したプログラムを作成することができます。初期状態では、何もインストールされていない一般的な音楽プレイヤーとして機能します。
                <br/>
                <br/>
                原則として、プラグインのインストールはメディアを設定する前に完了しておく必要があります。
              </p>
            )
          },
          {
            type: "code",
            content: `
              const player =
                new Songle.Player({
                  mediaElement: "#songle"
                });

              player.on("ready",
                function() {
                  player.play();
                });

              player.addPlugin(new Songle.Plugin.Beat());
              player.addPlugin(new Songle.Plugin.Chord());

              player.useMedia("https://www.youtube.com/watch?v=6j7VwJ7sM_k");
            `
          }
        ]
      },
      {
        name: "useMedia",
        type: "void",
        contents: [
          {
            type: "text",
            content: "メディアを設定します。"
          },
          {
            type: "table",
            titleAs: "h3",
            title: "Args",
            subtitle: "Args of this method",
            content: {
              headers: [
                {
                  style: { width: "15%" },
                  content: "名前"
                },
                {
                  style: { width: "15%" },
                  content: "型"
                },
                {
                  style: { width: "10%" },
                  content: "必須"
                },
                {
                  style: { width: "10%" },
                  content: "初期値"
                },
                {
                  style: { width: "50%" },
                  content: "説明"
                }
              ],
              bodies: [
                {
                  cells: [
                    {
                      content: "第１引数"
                    },
                    {
                      content: "string | Media.SuperClass"
                    },
                    {
                      content: "true"
                    },
                    {
                      content: "null"
                    },
                    {
                      content: (
                        <p>
                          Songle に登録されている楽曲の配信元 URL を指定します。
                        </p>
                      )
                    }
                  ]
                },
                {
                  cells: [
                    {
                      content: "第２引数"
                    },
                    {
                      content: "object"
                    },
                    {
                      content: "false"
                    },
                    {
                      content: "null"
                    },
                    {
                      content: (
                        <p>
                          オプション
                        </p>
                      )
                    }
                  ]
                }
              ]
            }
          },
          {
            type: "note",
            title: "メディアについて",
            content: (
              <p>
                Songle API では、インターネット上に公開された楽曲のことを「メディア」と呼ばれる抽象化された概念として扱います。メディアとして扱うことができる楽曲の種類の詳細については、<a href="//docs.songle.jp/help" target="_">こちらのヘルプページ</a>をご確認ください。
              </p>
            )
          },
          {
            type: "code",
            content: `
              const player =
                new Songle.Player({
                  mediaElement: "#songle"
                });

              player.on("ready",
                function() {
                  player.play();
                });

              player.useMedia("https://www.youtube.com/watch?v=6j7VwJ7sM_k");
            `
          }
        ]
      },
      {
        name: "activate",
        type: "void",
        contents: [
          {
            type: "text",
            content: "アクティベートします。"
          },
          {
            type: "note",
            title: "アクティベートについて",
            content: "使用しているメディア及び端末によっては、初回再生時に配信元のプレイヤーをユーザの意思によりクリックもしくはタップをさせた上で再生する（アクティベートさせる）必要があります。これは、自動再生による意図しない通信圧迫を防ぐ目的で導入されている技術です。"
          }
        ]
      },
      {
        name: "play",
        type: "void",
        contents: [
          {
            type: "text",
            content: "再生します。"
          }
        ]
      },
      {
        name: "pause",
        type: "void",
        contents: [
          {
            type: "text",
            content: "一時停止します。"
          }
        ]
      },
      {
        name: "stop",
        type: "void",
        contents: [
          {
            type: "text",
            content: "停止します。"
          }
        ]
      },
      {
        name: "seekTo",
        type: "void",
        contents: [
          {
            type: "text",
            content: "シークします。"
          }
        ]
      },
      {
        name: "on",
        type: "void",
        contents: [
          {
            type: "text",
            content: "イベンドハンドラを設定します。"
          },
          {
            type: "code",
            content: `
              player.on("ready",
                function()
                {
                  player.play();
                });
            `
          }
        ]
      }
    ],
    events: [
      {
        name: "error",
        contents: [
          {
            type: "text",
            content: "例外処理が発生した場合に呼び出されるイベントです。"
          }
        ]
      },
      {
        name: "ready",
        contents: [
          {
            type: "text",
            content: "準備処理が完了した場合に呼び出されるイベントです。"
          }
        ]
      },
      {
        name: "play",
        contents: [
          {
            type: "text",
            content: "再生された場合に呼び出されるイベントです。"
          }
        ]
      },
      {
        name: "pause",
        contents: [
          {
            type: "text",
            content: "停止された場合に呼び出されるイベントです。"
          }
        ]
      },
      {
        name: "seek",
        contents: [
          {
            type: "text",
            content: "シークされた場合に呼び出されるイベントです。"
          }
        ]
      },
      {
        name: "finish",
        contents: [
          {
            type: "text",
            content: "最後まで再生された場合に呼び出されるイベントです。"
          }
        ]
      },
      {
        name: "mediaError",
        contents: [
          {
            type: "text",
            content: "メディアに関する例外処理が発生した場合に呼び出されるイベントです。"
          }
        ]
      },
      {
        name: "mediaReady",
        contents: [
          {
            type: "text",
            content: "メディアに関する準備処理が完了した場合に呼び出されるイベントです。"
          }
        ]
      },
      {
        name: "mediaStateChange",
        contents: [
          {
            type: "text",
            content: "メディアの状態が変化した場合に呼び出されるイベントです。"
          },
          {
            type: "note",
            title: "接頭辞に media が付くイベントと付かないイベントについて",
            content: (
              <p>
                これらの違いは「メディア側の都合によりメディアの状態が変化したか否か」です。ユーザが <a href="#Player.play"><code>Player.play</code></a> などの Songle API メソッドを介してメディアを再生させた場合は <code>mediaPlay</code> と <code>play</code> が呼び出されます。逆にメディアが何らかの要因で自動再生した場合は <code>mediaPlay</code> のみが呼び出されます。カジュアルにプログラミングをする場合は、接頭辞のない方のイベントを使うと良いでしょう。
              </p>
            )
          }
        ]
      },
      {
        name: "mediaPlay",
        contents: [
          {
            type: "text",
            content: "メディアが再生された場合に呼び出されるイベントです。"
          }
        ]
      },
      {
        name: "mediaPause",
        contents: [
          {
            type: "text",
            content: "メディアが停止された場合に呼び出されるイベントです。"
          }
        ]
      },
      {
        name: "mediaSeek",
        contents: [
          {
            type: "text",
            content: "メディアがシークされた場合に呼び出されるイベントです。"
          }
        ]
      },
      {
        name: "mediaFinish",
        contents: [
          {
            type: "text",
            content: "メディアが最後まで再生された場合に呼び出されるイベントです。"
          }
        ]
      }
    ]
  };
}

const modules = SonglePlayerClassRef;

export default modules;
