//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import ClassRef from "./ClassRef";

/**
 * @class
 */
class PluginVariationClassRef extends ClassRef
{
  static defaultProps = {
    name: "Plugin.Variation",
    type: "class",
    contents: [
      {
        type: "text",
        content: "音楽地図のバリエーションを扱うプラグインクラスです。"
      },
      {
        type: "table",
        titleAs: "h3",
        title: "Constructor",
        subtitle: "1st arg options of the constructor",
        content: {
          headers: [
            {
              style: { width: "10%" },
              content: "名前"
            },
            {
              style: { width: "10%" },
              content: "型"
            },
            {
              style: { width: "10%" },
              content: "初期値"
            },
            {
              style: { width: "70%" },
              content: "説明"
            }
          ],
          bodies: [
            {
              cells: [
                {
                  content: (
                    <code>offset</code>
                  )
                },
                {
                  content: "number"
                },
                {
                  content: "0"
                },
                {
                  content: "イベントのオフセット値（ミリ秒）です。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>pollingInterval</code>
                  )
                },
                {
                  content: "number"
                },
                {
                  content: "50"
                },
                {
                  content: "イベントのポーリング値（ミリ秒）です。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>groupCount</code>
                  )
                },
                {
                  content: "number"
                },
                {
                  content: "2"
                },
                {
                  content: "分割するグループ数です。"
                }
              ]
            }
          ]
        }
      },
      {
        type: "code",
        content: `
          player.addPlugin(new Songle.Plugin.Variation());

          player.on("variationEnter",
            function(ev) {
              switch(ev.data.variation.group) {
                case 0:
                  // do someting ...
                  break;
                case 1:
                  // do someting ...
                  break;
                case 2:
                  // do someting ...
                  break;
                case 3:
                  // do someting ...
                  break;
              }
            });
        `
      },
      {
        title: "バリエーションについて",
        type: "note",
        content: (
          <div>
            <p>
              バリエーションとは、楽曲の盛り上がりに応じて分割された区間のことです。楽曲に応じた演出の選択などの用途で利用することが想定されています。例えば、分割するグループ数を４とした場合、楽曲全体を４つのグループ（バリエーション区間）に分割します。
            </p>
            <div style={{ margin: "24px 0px", width: "100%" }}>
              <div style={{ margin: "0px auto", border: "1px solid #888", width: "80%" }}>
                <div style={{ display: "inline-block", borderRight: "1px solid #888", textAlign: "center", color: "#888", width: "15%", height: "20px" }}>Group 0</div>
                <div style={{ display: "inline-block", borderRight: "1px solid #888", textAlign: "center", color: "#888", width: "25%", height: "20px" }}>Group 2</div>
                <div style={{ display: "inline-block", borderRight: "1px solid #888", textAlign: "center", color: "#888", width: "45%", height: "20px" }}>Group 3</div>
                <div style={{ display: "inline-block", borderRight: "0px solid #888", textAlign: "center", color: "#888", width: "15%", height: "20px" }}>Group 1</div>
              </div>
            </div>
            <p>
              バリエーションでは、再生位置に応じた区間の開始・終了でイベントが発生します。イベント発生時に返されるグループ番号は、音響的に派手である区間に対応しており、値が小さほど静かな区間、値が大きい程派手な区間であるとラベリングされています。
            </p>
          </div>
        )
      },
      {
        title: "オフセットについて",
        type: "note",
        content: (
          <p>
            オフセットでは、イベントの実行タイミングにオフセットを与えることができます。これは、アプリケーションの処理負荷などの要因によって、イベントの実行タイミングが前後にズレる場合に役立ちます。また、オフセットはイベント毎に個別に与えることもできます。
          </p>
        )
      },
      {
        type: "code",
        content: `
          player.addPlugin(new Songle.Plugin.Variation({
            offset: -100,
          });

          player.on("variationEnter",
            function(ev) {
              // this event will be run faster 100ms than the original variation event
            });

          player.on("variationEnter",
            function(ev) {
              // this event will be run faster 200ms than the original variation event
            }, { offset: -100 });

          player.on("variationEnter",
            function(ev) {
              // this event will be run faster 300ms than the original variation event
            }, { offset: -200 });
        `
      }
    ],
    constants: [
      {
        name: "MinGroupCount",
        type: "number",
        contents: [
          {
            type: "text",
            content: "分割するグループ数に設定できる最小値の定数です。"
          }
        ]
      },
      {
        name: "MaxGroupCount",
        type: "number",
        contents: [
          {
            type: "text",
            content: "分割するグループ数に設定できる最大値の定数です。"
          }
        ]
      },
      {
        name: "VariationEnterTrapName",
        type: "string",
        contents: [
          {
            type: "text",
            content: "バリエーションの開始で呼び出されるイベント名の定数です。"
          }
        ]
      },
      {
        name: "VariationLeaveTrapName",
        type: "string",
        contents: [
          {
            type: "text",
            content: "バリエーションの終了で呼び出されるイベント名の定数です。"
          }
        ]
      }
    ],
    properties: [
      {
        name: "offset",
        type: "number",
        contents: [
          {
            type: "text",
            content: "イベントのオフセット値（ミリ秒）です。"
          }
        ]
      },
      {
        name: "pollingInterval",
        type: "number",
        contents: [
          {
            type: "text",
            content: "イベントのポーリング値（ミリ秒）です。"
          }
        ]
      }
    ],
    events: [
      {
        name: "variationEnter",
        contents: [
          {
            type: "text",
            content: "バリエーションの開始で呼び出されるイベントです。"
          },
          {
            type: "code",
            content: `
              player.on("variationEnter",
                function(ev) {
                  console.log(ev);

                  /**
                  {
                    data: {
                      variation: Variation
                    }
                  }
                  */

                  /**
                  // バリエーション構造
                  type Variation = {
                    index: number,           // インデックス
                    startTime: number,       // バリエーションの開始位置（ミリ秒）
                    startTimeSec: number,    // バリエーションの開始位置（秒）
                    endTime: number,         // バリエーションの終了位置（ミリ秒）
                    endTimeSec: number,      // バリエーションの終了位置（秒）
                    durationTime: number,    // バリエーションの再生時間（ミリ秒）
                    durationTimeSec: number, // バリエーションの再生時間（秒）
                    group: number,           // バリエーションのグループ番号
                    prev: Variation,         // 前のバリエーション構造の参照
                    next: Variation          // 次のバリエーション構造の参照
                  }
                  */
                });
            `
          }
        ]
      },
      {
        name: "variationLeave",
        contents: [
          {
            type: "text",
            content: "バリエーションの終了で呼び出されるイベントです。"
          },
          {
            type: "code",
            content: `
              player.on("variationLeave",
                function(ev) {
                  console.log(ev);

                  /**
                  {
                    data: {
                      variation: Variation
                    }
                  }
                  */

                  /**
                  // バリエーション構造
                  type Variation = {
                    index: number,           // インデックス
                    startTime: number,       // バリエーションの開始位置（ミリ秒）
                    startTimeSec: number,    // バリエーションの開始位置（秒）
                    endTime: number,         // バリエーションの終了位置（ミリ秒）
                    endTimeSec: number,      // バリエーションの終了位置（秒）
                    durationTime: number,    // バリエーションの再生時間（ミリ秒）
                    durationTimeSec: number, // バリエーションの再生時間（秒）
                    group: number,           // バリエーションのグループ番号
                    prev: Variation,         // 前のバリエーション構造の参照
                    next: Variation          // 次のバリエーション構造の参照
                  }
                  */
                });
            `
          }
        ]
      }
    ]
  };
}

const modules = PluginVariationClassRef;

export default modules;
