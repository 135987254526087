//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Grid, Image, Label, List } from "semantic-ui-react";

import ArticleTemplate from "../../../App/components/AppPage/ArticleTemplate";

import ImgEventMagicalmirai2017 from "../../../../../assets/document_module/overview/event-magicalmirai2017";
import ImgEventMagicalmirai2018 from "../../../../../assets/document_module/overview/event-magicalmirai2018";
import ImgEventSnowmiku2018 from "../../../../../assets/document_module/overview/event-snowmiku2018";

/**
 * @class
 */
class News extends ArticleTemplate
{
  static defaultProps = {
    articles: [
      {
        type: "text",
        anchor: "section2",
        titleAs: "h2",
        titleEn: "News",
        titleJa: "ニュース",
        contentEn: (
          <List style={{ marginLeft: "24px" }}>
            <List.Item>
              <List.Header
                as="a"
                href="//events.ongaaccel.jp/mikusync2020?lang=en"
                target="_">
                <Label style={{ marginRight: "12px" }}>
                  2020/08/31
                </Label>
                Digital Stars presents Birthday Message Project for Hatsune Miku: A Collaboration with Songle Sync
              </List.Header>
            </List.Item>
            <List.Item>
              <List.Header
                as="a"
                href="//events.ongaaccel.jp/magicalmirai2019?lang=en"
                target="_">
                <Label style={{ marginRight: "12px" }}>
                  2019/08/31
                </Label>
                Magical Mirai 2019 Birthday Message Project for Hatsune Miku: A Collaboration with Songle Sync
              </List.Header>
            </List.Item>
            <List.Item>
              <List.Header
                as="a"
                href="//events.ongaaccel.jp/magicalmirai2018?lang=en"
                target="_">
                <Label style={{ marginRight: "12px" }}>
                  2018/08/31
                </Label>
                Magical Mirai 2018 Birthday Message Project for Hatsune Miku: A Collaboration with Songle Sync
              </List.Header>
            </List.Item>
            <List.Item>
              <List.Header
                as="a"
                href="//events.ongaaccel.jp/snowmiku2018?lang=en"
                target="_">
                <Label style={{ marginRight: "12px" }}>
                  2018/02/09
                </Label>
                SNOW MIKU LIVE! 2018: A Collaboration with Songle Sync
              </List.Header>
            </List.Item>
            <List.Item>
              <List.Header
                as="a"
                href="//events.ongaaccel.jp/magicalmirai2017?lang=en"
                target="_">
                <Label style={{ marginRight: "12px" }}>
                  2017/09/02
                </Label>
                Magical Mirai 2017 keisei × Mikudayo- DJ Stage: A Collaboration with Songle Sync
              </List.Header>
            </List.Item>
            <List.Item>
              <List.Header
                as="a"
                href="//www.aist.go.jp/aist_e/list/latest_research/2019/20190411/en20190411.html"
                target="_">
                <Label style={{ marginRight: "12px" }}>
                  2017/08/02
                </Label>
                Songle Sync press release
              </List.Header>
            </List.Item>
          </List>
        ),
        contentJa: (
          <List style={{ marginLeft: "24px" }}>
            <List.Item>
              <List.Header
                as="a"
                href="//events.ongaaccel.jp/mikusync2020"
                target="_">
                <Label style={{ marginRight: "12px" }}>
                  2020/08/31
                </Label>
                「Digital Stars presents 初音ミクバースデーメッセージ企画」Songle Sync コラボを開催しました。世界中からのべ 10,590 人の方々にご参加いただきました。
              </List.Header>
            </List.Item>
            <List.Item>
              <List.Header
                as="a"
                href="//events.ongaaccel.jp/magicalmirai2019"
                target="_">
                <Label style={{ marginRight: "12px" }}>
                  2019/08/31
                </Label>
                「マジカルミライ 2019 初音ミクバースデーメッセージ企画」Songle Sync コラボを開催しました。
              </List.Header>
            </List.Item>
            <List.Item>
              <List.Header
                as="a"
                href="//events.ongaaccel.jp/magicalmirai2018"
                target="_">
                <Label style={{ marginRight: "12px" }}>
                  2018/08/31
                </Label>
                「マジカルミライ 2018 初音ミクバースデーメッセージ企画」Songle Sync コラボを開催しました。
              </List.Header>
            </List.Item>
            <List.Item>
              <List.Header
                as="a"
                href="//events.ongaaccel.jp/snowmiku2018"
                target="_">
                <Label style={{ marginRight: "12px" }}>
                  2018/02/09
                </Label>
                「SNOW MIKU LIVE! 2018」Songle Sync コラボを開催しました。
              </List.Header>
            </List.Item>
            <List.Item>
              <List.Header
                as="a"
                href="//events.ongaaccel.jp/magicalmirai2017"
                target="_">
                <Label style={{ marginRight: "12px" }}>
                  2017/09/02
                </Label>
                「マジカルミライ 2017 keisei x ミクダヨー DJ ステージ」Songle Sync コラボを開催しました。
              </List.Header>
            </List.Item>
            <List.Item>
              <List.Header
                as="a"
                href="//www.aist.go.jp/aist_j/press_release/pr2017/pr20170802/pr20170802.html"
                target="_">
                <Label style={{ marginRight: "12px" }}>
                  2017/08/02
                </Label>
                Songle Sync をプレス発表しました。
              </List.Header>
            </List.Item>
          </List>
        )
      },
      {
        type: "text",
        contentEn: (
          <Grid columns={2}>
            <Grid.Column textAlign="center">
              <Image
                size="huge"
                src={ImgEventMagicalmirai2017} />
              Magical Mirai 2017 Collaboration
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Image
                size="huge"
                src={ImgEventMagicalmirai2018} />
              Magical Mirai 2018 Collaboration
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Image
                size="huge"
                src={ImgEventSnowmiku2018} />
              SNOW MIKU LIVE! 2018 Collaboration
            </Grid.Column>
          </Grid>
        ),
        contentJa: (
          <Grid columns={2}>
            <Grid.Column textAlign="center">
              <Image
                size="huge"
                src={ImgEventMagicalmirai2017} />
              マジカルミライ 2017 企画展
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Image
                size="huge"
                src={ImgEventMagicalmirai2018} />
              マジカルミライ 2018 企画展
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Image
                size="huge"
                src={ImgEventSnowmiku2018} />
              SNOW MIKU LIVE! 2018
            </Grid.Column>
          </Grid>
        )
      }
    ]
  }
}

const modules = News;

export default modules;
