//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import ClassRef from "./ClassRef";

/**
 * @class
 */
class MediaHeadlessClassRef extends ClassRef
{
  static defaultProps = {
    name: "Media.Headless",
    type: "class",
    contents: [
      {
        type: "text",
        content: (
          <p>
            音楽の再生を必要としない場合に利用するメディアクラスです。<br/>
            例えば、スピーカーを持たない端末などから、音楽地図のみを利用したい場合に利用します。
          </p>
        )
      },
      {
        type: "code",
        content: `
          const media =
            new Songle.Media.Headless("https://www.youtube.com/watch?v=6j7VwJ7sM_k");

          player.useMedia(media);
        `
      }
    ]
  };
}

const modules = MediaHeadlessClassRef;

export default modules;
