//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Header, Segment } from "semantic-ui-react";

import ArticleTemplate from "../../../App/components/AppPage/ArticleTemplate";

/**
 * @class
 */
const ClassAttrRef = (
  props
) =>
{
  const { anchorPrefix, contents } = props;

  if(!contents) {
    return (
      <div />
    );
  }

  return contents.map((content) => {
    const { name, type, contents } = content;

    const anchorName = `${anchorPrefix}.${name}`;

    return (
      <Segment
        key={anchorName}
        id={anchorName}>
        <Header as="h3">
          <code>{name}{type ? `: ${type}` : ``}</code>
        </Header>
        <ArticleTemplate articles={contents} />
      </Segment>
    );
  });
}

const modules = ClassAttrRef;

export default modules;
