//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import ClassRef from "./ClassRef";

/**
 * @class
 */
class SystemClassRef extends ClassRef
{
  static defaultProps = {
    name: "System",
    type: "class",
    contents: [
      {
        type: "text",
        content: "Songle JavaScript API のシステムクラスです。"
      }
    ],
    staticProperties: [
      {
        name: "showDebugLog",
        type: "boolean",
        contents: [
          {
            type: "text",
            content: (
              <span>
                開発ログを開発者コンソールに表示するための設定です（デフォルト：<strong>無効</strong>）。
              </span>
            )
          },
          {
            type: "code",
            content: `
              Songle.System.showDebugLog = true;
            `
          }
        ]
      },
      {
        name: "showErrorLog",
        type: "boolean",
        contents: [
          {
            type: "text",
            content: (
              <span>
                例外ログを開発者コンソールに表示するための設定です（デフォルト：<strong>無効</strong>）。
              </span>
            )
          },
          {
            type: "code",
            content: `
              Songle.System.showErrorLog = true;
            `
          }
        ]
      },
      {
        name: "showLicense",
        type: "boolean",
        contents: [
          {
            type: "text",
            content: (
              <span>
                ライセンスの表示設定です（デフォルト：<strong>有効</strong>）。
              </span>
            )
          },
          {
            type: "code",
            content: `
              Songle.System.showLicense = false;
            `
          },
          {
            type: "note",
            title: "ライセンス表示の無効化について",
            content: (
              <span>
                このメソッドは、ウェブサイトを構築する際に「デザイン上、止むを得ずライセンス表示を削除したい」場合を想定して用意されたものです。本システム Songle API は非商用利用に限り、どなたでも無料・無保証で利用することができます。ただし、原則として Songle API を利用している旨を<a href="/">本サイト</a>にハイパーリンクした上で、利用しているシステムもしくは外部サイト等に表示する必要があります。
              </span>
            )
          }
        ]
      }
    ]
  };
}

const modules = SystemClassRef;

export default modules;
