//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import ClassRef from "./ClassRef";

/**
 * @class
 */
class PluginChorusClassRef extends ClassRef
{
  static defaultProps = {
    name: "Plugin.Chorus",
    type: "class",
    contents: [
      {
        type: "text",
        content: "音楽地図のサビを扱うプラグインクラスです。"
      },
      {
        type: "table",
        titleAs: "h3",
        title: "Constructor",
        subtitle: "1st arg options of the constructor",
        content: {
          headers: [
            {
              style: { width: "10%" },
              content: "名前"
            },
            {
              style: { width: "10%" },
              content: "型"
            },
            {
              style: { width: "10%" },
              content: "初期値"
            },
            {
              style: { width: "70%" },
              content: "説明"
            }
          ],
          bodies: [
            {
              cells: [
                {
                  content: (
                    <code>revisionId</code>
                  )
                },
                {
                  content: "number"
                },
                {
                  content: "0"
                },
                {
                  content: "使用する音楽地図のリビジョン番号です。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>offset</code>
                  )
                },
                {
                  content: "number"
                },
                {
                  content: "0"
                },
                {
                  content: "イベントのオフセット値（ミリ秒）です。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>pollingInterval</code>
                  )
                },
                {
                  content: "number"
                },
                {
                  content: "50"
                },
                {
                  content: "イベントのポーリング値（ミリ秒）です。"
                }
              ]
            }
          ]
        }
      },
      {
        type: "code",
        content: `
          player.addPlugin(new Songle.Plugin.Chorus());

          player.on("chorusSectionEnter",
            function(ev) {
              // do someting ...
            });
        `
      },
      {
        type: "note",
        title: "サビ区間と繰り返し区間について",
        content: (
          <div>
            <div style={{ margin: "24px 0px", padding: "0px 4px", width: "100%", border: "1px solid #888" }}>
              <div style={{ margin: "0px auto", width: "100%" }}>
                <div style={{ display: "inline-block", border: "0px solid #f84", textAlign: "center", color: "#f84", width: "40%", height: "20px" }} />
                <div style={{ display: "inline-block", border: "1px solid #f84", textAlign: "center", color: "#f84", width: "40%", height: "20px" }}>サビ</div>
                <div style={{ display: "inline-block", border: "0px solid #f84", textAlign: "center", color: "#f84", width: "10%", height: "20px" }} />
                <div style={{ display: "inline-block", border: "0px solid #f84", textAlign: "center", color: "#f84", width: "10%", height: "20px" }} />
              </div>
              <div style={{ margin: "0px auto", width: "100%" }}>
                <div style={{ display: "inline-block", border: "0px solid #48f", textAlign: "center", color: "#48f", width: "40%", height: "20px" }} />
                <div style={{ display: "inline-block", border: "1px solid #48f", textAlign: "center", color: "#48f", width: "20%", height: "20px" }}>繰り返し</div>
                <div style={{ display: "inline-block", border: "1px solid #48f", textAlign: "center", color: "#48f", width: "20%", height: "20px" }}>繰り返し</div>
                <div style={{ display: "inline-block", border: "0px solid #48f", textAlign: "center", color: "#48f", width: "10%", height: "20px" }} />
              </div>
              <div style={{ margin: "0px auto", width: "100%" }}>
                <div style={{ display: "inline-block", border: "1px solid #48f", textAlign: "center", color: "#48f", width: "20%", height: "20px" }}>繰り返し</div>
                <div style={{ display: "inline-block", border: "0px solid #48f", textAlign: "center", color: "#48f", width: "30%", height: "20px" }} />
                <div style={{ display: "inline-block", border: "0px solid #48f", textAlign: "center", color: "#48f", width: "30%", height: "20px" }} />
                <div style={{ display: "inline-block", border: "1px solid #48f", textAlign: "center", color: "#48f", width: "20%", height: "20px" }}>繰り返し</div>
              </div>
              <div style={{ margin: "0px auto", width: "100%" }}>
                ...
              </div>
            </div>
            <p>
              この図は、<a href="//songle.jp/songs/610565" target="_">楽曲訂正インタフェース</a>における、サビ及び繰り返し構造の可視化を模倣したものです。サビ区間は、楽曲訂正インタフェースにおける橙色の区間を指し、繰り返し区間は、楽曲訂正インタフェースにおける橙色と青色の区間を指します。
            </p>
          </div>
        )
      },
      {
        title: "リビジョンについて",
        type: "note",
        content: (
          <p>
            リビジョンとは、音楽理解技術による自動解析の誤りを人間が訂正した際に残る編集履歴のことです。この番号を指定することで、アプリケーションで使用する音楽地図の訂正状態を固定することができます。これは、音楽地図が第三者により編集された場合に発生する不整合を防ぐ場合に役立ちます。リビジョン番号は、<a href="//songle.jp/songs/610565" target="_">楽曲ページ</a>の<a href="//songle.jp/songs/610565/history" target="_">編集履歴</a>より確認することができます。
          </p>
        )
      },
      {
        title: "オフセットについて",
        type: "note",
        content: (
          <p>
            オフセットでは、イベントの実行タイミングにオフセットを与えることができます。これは、アプリケーションの処理負荷などの要因によって、イベントの実行タイミングが前後にズレる場合に役立ちます。また、オフセットはイベント毎に個別に与えることもできます。
          </p>
        )
      },
      {
        type: "code",
        content: `
          player.addPlugin(new Songle.Plugin.Chorus({
            offset: -100,
          });

          player.on("chorusSectionEnter",
            function(ev) {
              // this event will be run faster 100ms than the original chorus event
            });

          player.on("chorusSectionEnter",
            function(ev) {
              // this event will be run faster 200ms than the original chorus event
            }, { offset: -100 });

          player.on("chorusSectionEnter",
            function(ev) {
              // this event will be run faster 300ms than the original chorus event
            }, { offset: -200 });
        `
      }
    ],
    constants: [
      {
        name: "ChorusSectionEnterTrapName",
        type: "string",
        contents: [
          {
            type: "text",
            content: "サビ区間の開始で呼び出されるイベント名の定数です。"
          }
        ]
      },
      {
        name: "ChorusSectionLeaveTrapName",
        type: "string",
        contents: [
          {
            type: "text",
            content: "サビ区間の終了で呼び出されるイベント名の定数です。"
          }
        ]
      },
      {
        name: "RepeatedSectionEnterTrapName",
        type: "string",
        contents: [
          {
            type: "text",
            content: "繰り返し区間の開始で呼び出されるイベント名の定数です。"
          }
        ]
      },
      {
        name: "RepeatedSectionLeaveTrapName",
        type: "string",
        contents: [
          {
            type: "text",
            content: "繰り返し区間の終了で呼び出されるイベント名の定数です。"
          }
        ]
      }
    ],
    properties: [
      {
        name: "offset",
        type: "number",
        contents: [
          {
            type: "text",
            content: "イベントのオフセット値（ミリ秒）です。"
          }
        ]
      },
      {
        name: "pollingInterval",
        type: "number",
        contents: [
          {
            type: "text",
            content: "イベントのポーリング値（ミリ秒）です。"
          }
        ]
      }
    ],
    getterProperties: [
      {
        name: "revisionId",
        type: "number",
        contents: [
          {
            type: "text",
            content: "使用する音楽地図のリビジョン番号です。"
          }
        ]
      }
    ],
    methods: [
      {
        name: "seekToPrevChorusSection",
        type: "void",
        contents: [
          {
            type: "text",
            content: (
              <p>
                現在の再生位置から後方に対して、最も近いサビ区間にシークします。<br/>
                このメソッドは <code>Player</code> クラスに同名のメソッド名がプラグイン読み込み前に定義されていない場合は <code>Player</code> クラスから呼び出すことができます。
              </p>
            )
          },
          {
            type: "code",
            content: `
              player.add(new Songle.Plugin.Chorus());

              player.on("ready",
                function()
                {
                  player.seekToPrevChorusSection();
                });
            `
          }
        ]
      },
      {
        name: "seekToNextChorusSection",
        type: "void",
        contents: [
          {
            type: "text",
            content: (
              <p>
                現在の再生位置から前方に対して、最も近いサビ区間にシークします。<br/>
                このメソッドは <code>Player</code> クラスに同名のメソッド名がプラグイン読み込み前に定義されていない場合は <code>Player</code> クラスから呼び出すことができます。
              </p>
            )
          },
          {
            type: "code",
            content: `
              player.add(new Songle.Plugin.Chorus());

              player.on("ready",
                function()
                {
                  player.seekToNextChorusSection();
                });
            `
          }
        ]
      },
      {
        name: "seekToPrevRepeatedSection",
        type: "void",
        contents: [
          {
            type: "text",
            content: (
              <p>
                現在の再生位置から後方に対して、最も近い繰り返し区間にシークします。<br/>
                このメソッドは <code>Player</code> クラスに同名のメソッド名がプラグイン読み込み前に定義されていない場合は <code>Player</code> クラスから呼び出すことができます。
              </p>
            )
          },
          {
            type: "code",
            content: `
              player.add(new Songle.Plugin.Chorus());

              player.on("ready",
                function()
                {
                  player.seekToPrevRepeatedSection();
                });
            `
          }
        ]
      },
      {
        name: "seekToNextRepeatedSection",
        type: "void",
        contents: [
          {
            type: "text",
            content: (
              <p>
                現在の再生位置から前方に対して、最も近い繰り返し区間にシークします。<br/>
                このメソッドは <code>Player</code> クラスに同名のメソッド名がプラグイン読み込み前に定義されていない場合は <code>Player</code> クラスから呼び出すことができます。
              </p>
            )
          },
          {
            type: "code",
            content: `
              player.add(new Songle.Plugin.Chorus());

              player.on("ready",
                function()
                {
                  player.seekToNextRepeatedSection();
                });
            `
          }
        ]
      }
    ],
    events: [
      {
        name: "chorusSectionEnter",
        contents: [
          {
            type: "text",
            content: (
              <p>
                サビ区間の開始で呼び出されるイベントです。<br/>
                区間が重なっている場合は、インデックスの値が小さい順に呼び出されます。
              </p>
            )
          },
          {
            type: "code",
            content: `
              player.on("chorusSectionEnter",
                function(ev) {
                  console.log(ev);

                  /**
                  {
                    data: {
                      section: ChorusSection,
                      sectionItem: ChorusSectionItem
                    }
                  }
                  */

                  /**
                  // サビ区間構造
                  type ChorusSection = {
                    index: number,                  // インデックス
                    isChorus: boolean,              // サビの判別フラグ
                    durationTime: number,           // 区間の再生時間（ミリ秒）
                    durationTimeSec: number,        // 区間の再生時間（秒）
                    items: Array<ChorusSectionItem>
                  }
                  */

                  /**
                  // サビ区間の要素構造
                  type ChorusSectionItem = {
                    index: number,                  // インデックス
                    startTime: number,              // 区間の開始位置（ミリ秒）
                    startTimeSec: number,           // 区間の開始位置（秒）
                    endTime: number,                // 区間の終了位置（ミリ秒）
                    endTimeSec: number,             // 区間の終了位置（秒）
                    durationTime: number,           // 区間の再生時間（ミリ秒）
                    durationTimeSec: number,        // 区間の再生時間（秒）
                    prev: ChorusSectionItem,        // 前のサビ区間の要素構造の参照
                    next: ChorusSectionItem         // 次のサビ区間の要素構造の参照
                  }
                  */
                });
            `
          }
        ]
      },
      {
        name: "chorusSectionLeave",
        contents: [
          {
            type: "text",
            content: "サビ区間の終了で呼び出されるイベントです。"
          },
          {
            type: "code",
            content: `
              player.on("barLeave",
                function(ev) {
                  console.log(ev);

                  /**
                  {
                    data: {
                      section: ChorusSection,
                      sectionItem: ChorusSectionItem
                    }
                  }
                  */

                  /**
                  // サビ区間構造
                  type ChorusSection = {
                    index: number,                  // インデックス
                    isChorus: boolean,              // サビの判別フラグ
                    durationTime: number,           // 区間の再生時間（ミリ秒）
                    durationTimeSec: number,        // 区間の再生時間（秒）
                    items: Array<ChorusSectionItem>
                  }
                  */

                  /**
                  // サビ区間の要素構造
                  type ChorusSectionItem = {
                    index: number,                  // インデックス
                    startTime: number,              // 区間の開始位置（ミリ秒）
                    startTimeSec: number,           // 区間の開始位置（秒）
                    endTime: number,                // 区間の終了位置（ミリ秒）
                    endTimeSec: number,             // 区間の終了位置（秒）
                    durationTime: number,           // 区間の再生時間（ミリ秒）
                    durationTimeSec: number,        // 区間の再生時間（秒）
                    prev: ChorusSectionItem,        // 前のサビ区間の要素構造の参照
                    next: ChorusSectionItem         // 次のサビ区間の要素構造の参照
                  }
                  */
                });
            `
          }
        ]
      },
      {
        name: "repeatedSectionEnter",
        contents: [
          {
            type: "text",
            content: "拍子の開始で呼び出されるイベントです。"
          },
          {
            type: "code",
            content: `
              player.on("chorusSectionEnter",
                function(ev) {
                  console.log(ev);

                  /**
                  {
                    data: {
                      section: ChorusSection,
                      sectionItem: ChorusSectionItem
                    }
                  }
                  */

                  /**
                  // サビ区間構造
                  type ChorusSection = {
                    index: number,                  // インデックス
                    isChorus: boolean,              // サビの判別フラグ
                    durationTime: number,           // 区間の再生時間（ミリ秒）
                    durationTimeSec: number,        // 区間の再生時間（秒）
                    items: Array<ChorusSectionItem>
                  }
                  */

                  /**
                  // サビ区間の要素構造
                  type ChorusSectionItem = {
                    index: number,                  // インデックス
                    startTime: number,              // 区間の開始位置（ミリ秒）
                    startTimeSec: number,           // 区間の開始位置（秒）
                    endTime: number,                // 区間の終了位置（ミリ秒）
                    endTimeSec: number,             // 区間の終了位置（秒）
                    durationTime: number,           // 区間の再生時間（ミリ秒）
                    durationTimeSec: number,        // 区間の再生時間（秒）
                    prev: ChorusSectionItem,        // 前のサビ区間の要素構造の参照
                    next: ChorusSectionItem         // 次のサビ区間の要素構造の参照
                  }
                  */
                });
            `
          }
        ]
      },
      {
        name: "repeatedSectionLeave",
        contents: [
          {
            type: "text",
            content: "拍子の終了で呼び出されるイベントです。"
          },
          {
            type: "code",
            content: `
              player.on("chorusLeave",
                function(ev) {
                  console.log(ev);

                  /**
                  {
                    data: {
                      section: ChorusSection,
                      sectionItem: ChorusSectionItem
                    }
                  }
                  */

                  /**
                  // サビ区間構造
                  type ChorusSection = {
                    index: number,                  // インデックス
                    isChorus: boolean,              // サビの判別フラグ
                    durationTime: number,           // 区間の再生時間（ミリ秒）
                    durationTimeSec: number,        // 区間の再生時間（秒）
                    items: Array<ChorusSectionItem>
                  }
                  */

                  /**
                  // サビ区間の要素構造
                  type ChorusSectionItem = {
                    index: number,                  // インデックス
                    startTime: number,              // 区間の開始位置（ミリ秒）
                    startTimeSec: number,           // 区間の開始位置（秒）
                    endTime: number,                // 区間の終了位置（ミリ秒）
                    endTimeSec: number,             // 区間の終了位置（秒）
                    durationTime: number,           // 区間の再生時間（ミリ秒）
                    durationTimeSec: number,        // 区間の再生時間（秒）
                    prev: ChorusSectionItem,        // 前のサビ区間の要素構造の参照
                    next: ChorusSectionItem         // 次のサビ区間の要素構造の参照
                  }
                  */
                });
            `
          }
        ]
      }
    ]
  };
}

const modules = PluginChorusClassRef;

export default modules;
