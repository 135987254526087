//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import ArticleTemplate from "../../../App/components/AppPage/ArticleTemplate";

/**
 * @class
 */
class Example1 extends ArticleTemplate
{
  static defaultProps = {
    articles: [
      {
        type: "text",
        anchor: "section2",
        titleAs: "h2",
        title: "サンプルプログラム１",
        content: (
          <div>
            <p>
              次のサンプルプログラムは、再生している音楽の拍子とコンソール出力を連動させるプログラムです。<br/>
              サンプルから分かるように「１拍目が再生されたら〜を実行する」といった、音楽に連動した<a href="//developer.mozilla.org/ja/docs/Learn/JavaScript/Building_blocks/Events" target="_">イベント駆動</a>（音楽連動制御）のプログラムが Songle API を使うことで容易に記述することができます。
            </p>
            <iframe
              src="//jsfiddle.net/0g9are4u/embedded/js,html,css,result"
              frameBorder="0"
              width="100%"
              height="640" />
          </div>
        )
      },
      {
        type: "note",
        title: "プラグインについて",
        content: (
          <p>
            サンプルプログラムに記述されているプラグインは、利用する機能に応じて追加することができます。例えば、音楽の和音に連動させる場合は Songle API モジュールの和音プラグイン <a href="/references/javascript#Plugin.Chord">Plugin.Chord</a> を追加することで、和音に連動したプログラムを作成することができます。
          </p>
        )
      },
      {
        type: "text",
        content: (
          <p>
            次のサンプルプログラムは、音楽の様々な要素とウェブコンテンツを組み合わせた実例になります。<br/>
            まずは音楽を再生してみて、音楽連動制御のイメージを掴んでみましょう。
          </p>
        )
      },
      {
        type: "text",
        content: (
          <iframe
            src="//jsfiddle.net/sxt4z79k/embedded/result,js,html,css"
            frameBorder="0"
            width="100%"
            height="640" />
        )
      },
      {
        type: "text",
        content: (
          <p>
            より詳しい Songle JavaScript API の機能を知りたい場合は<a href="/references/javascript">リファレンス</a>を参照してください。
          </p>
        )
      }
    ]
  }
}

const modules = Example1;

export default modules;
