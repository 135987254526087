//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Button, Image, List, Step } from "semantic-ui-react";

import ArticleTemplate from "../../../App/components/AppPage/ArticleTemplate";

import ImgOverview1 from "../../../../../assets/document_module/overview/overview1";
import ImgOverview2 from "../../../../../assets/document_module/overview/overview2";

/**
 * @class
 */
class AbstractSongleSync extends ArticleTemplate
{
  static defaultProps = {
    articles: [
      {
        type: "text",
        anchor: "section3",
        titleAs: "h2",
        title: "大規模音楽連動制御",
      },
      {
        type: "text",
        titleAs: "h3",
        title: "Songle Sync （ソングルシンク）とは",
        content: (
          <div>
            <p>
              ウェブ上の音楽の再生に合わせて多種多様な機器を同時制御することで、一体感のある演出ができる大規模音楽連動制御プラットフォームです。誰でも自分のスマートフォンやパソコン等の機器を自由自在に組み合わせて、好きな楽曲の再生に合わせて光ったり動いたりする演出を作ることができます。
            </p>
            <Image
              centered
              size="huge"
              src={ImgOverview1}
              style={{ marginTop: "48px" }} />
          </div>
        )
      },
      {
        type: "text",
        titleAs: "h3",
        title: "はじめに",
        content: (
          <div>
            <p>
              この機能を使うためには Songle API のウェブサイトからユーザ登録を行い、トークンを取得する必要があります。
            </p>
            <div style={{ margin: "48px 0px", textAlign: "center" }}>
              <Step.Group ordered>
                <Step>
                  <Step.Content>
                    <Step.Title>
                      <a href="/u/sign_in">
                        ユーザー登録
                      </a>
                    </Step.Title>
                  </Step.Content>
                </Step>
                <Step>
                  <Step.Content>
                    <Step.Title>
                      <a href="/u/apps">
                        トークン取得
                      </a>
                    </Step.Title>
                  </Step.Content>
                </Step>
                <Step>
                  <Step.Content>
                    <Step.Title>
                      <a href="#section04">
                        利用開始
                      </a>
                    </Step.Title>
                  </Step.Content>
                </Step>
              </Step.Group>
            </div>
          </div>
        )
      },
      {
        type: "text",
        titleAs: "h3",
        title: "チュートリアル",
        content: (
          <div>
            <p>
              チュートリアルサイトでは、より実践的な使い方を実際に体験しながら学ぶことができます。<br/>
              チュートリアルの内容は、アプリケーション開発者をメインターゲットとしていますが、途中までは、プログラミングの知識がない方でもより高度な大規模音楽連動制御を楽しみながら体験できます。
            </p>
            <div style={{ margin: "48px 0px", textAlign: "center" }}>
              <Button
                color="pink"
                size="large"
                as="a"
                href="//tutorial.songle.jp/sync"
                target="_">
                チュートリアルサイトへ
              </Button>
            </div>
          </div>
        )
      },
      {
        type: "text",
        titleAs: "h3",
        title: "Songle Sync ステージについて",
        content: (
          <div>
            <p>
              大規模音楽連動制御に参加する端末が集まる仮想的空間のことを「ステージ」と呼びます。<br/>
              トークンを取得することは、このステージを作ることにあたり、ステージは目的に応じていくつでも作ることができます。
            </p>
            <Image
              centered
              size="huge"
              src={ImgOverview2}
              style={{ marginTop: "48px" }} />
          </div>
        )
      },
      {
        type: "text",
        titleAs: "h3",
        title: "トークンのタイプ",
        content: (
          <div>
            <p>
              トークンには、３つのタイプが存在します。
            </p>
            <List style={{ marginLeft: "24px" }}>
              <List.Item>
                <List.Content>
                  <List.Header>
                    アプリトークン
                  </List.Header>
                  <List.List>
                    <List.Item>
                      ステージを作成・削除するためのトークンです。<br/>
                      例えば、ユーザの目的毎にステージを分けたいなどの理由で、ステージをいくつも用意する必要がある場合に使用するトークンです。
                    </List.Item>
                  </List.List>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header>
                    認証用トークン
                  </List.Header>
                  <List.List>
                    <List.Item>
                      ステージを管理するためのトークンです。
                    </List.Item>
                  </List.List>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header>
                    参加用トークン
                  </List.Header>
                  <List.List>
                    <List.Item>
                      ステージに参加するためのトークンです。
                    </List.Item>
                  </List.List>
                </List.Content>
              </List.Item>
            </List>
          </div>
        )
      },
      {
        type: "text",
        titleAs: "h3",
        title: "ステージに参加する端末のタイプ",
        content: (
          <div>
            <p>
              ステージに参加する端末には２つのタイプが存在します。
            </p>
            <List style={{ marginLeft: "24px" }}>
              <List.Item>
                <List.Content>
                  <List.Header content="Songle Sync マスター" />
                  <List.List>
                    <List.Item>
                      ステージに参加する端末のうち、<strong>音楽の再生・停止などの制御が許可されてる端末のこと</strong>を「マスター」と呼びます。<br/>
                      マスターとして参加する端末は、認証用トークンを知っている必要があります。
                    </List.Item>
                  </List.List>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header content="Songle Sync スレーブ" />
                  <List.List>
                    <List.Item>
                      ステージに参加する端末のうち、<strong>音楽の再生・停止などの制御が許可されない端末のこと</strong>を「スレーブ」と呼びます。<br/>
                      スレーブとして参加する端末は、参加用トークンを知っている必要があります。
                    </List.Item>
                  </List.List>
                </List.Content>
              </List.Item>
            </List>
          </div>
        )
      },
      {
        type: "text",
        titleAs: "h3",
        title: "ステージの参加方法",
        content: (
          <p>
            各端末は、何らかの共有方法（例えば電子メールや QR コードなど）で事前に知った参加用トークンを使ってステージに参加します。<br/>
            参加した端末は、マスターの再生状態に従って大規模音楽連動制御が開始されます。
          </p>
        )
      },
      {
        type: "note",
        title: "トークンの共有範囲について",
        content: (
          <p>
            参加用トークンは、アプリケーションを利用する全員が知る可能性のあるトークンですが、認証用トークンは、多くの場合、アプリケーション開発者や音楽の再生・停止を制御するユーザーのみが知るトークンとなります。このトークンが意図しない第三者に知られると、音楽を勝手に切り替えたり、再生・停止させることができるため、共有には注意が必要です。
          </p>
        )
      }
    ]
  }
}

const modules = AbstractSongleSync;

export default modules;
