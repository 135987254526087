//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import ClassRef from "./ClassRef";

/**
 * @class
 */
class SyncPlayerClassRef extends ClassRef
{
  static defaultProps = {
    name: "SyncPlayer",
    type: "class",
    contents: [
      {
        type: "text",
        content: (
          <p>
            音楽プレイヤーを抽象化したクラスです。<br/>
            このクラスを利用して初期化された <code>Player</code> は、次のコードと同じ意味になります。
          </p>
        )
      },
      {
        type: "code",
        content: `
          const player =
            new Songle.Player({
              mediaElement: "#songle"
            });

          player.addPlugin(Songle.Plugin.SongleSync());
        `
      }
    ]
  };
}

const modules = SyncPlayerClassRef;

export default modules;
