//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import ClassRef from "./ClassRef";

/**
 * @class
 */
class PluginSongleWidgetClassRef extends ClassRef
{
  static defaultProps = {
    name: "Plugin.SongleWidget",
    type: "class",
    contents: [
      {
        type: "text",
        content: "外部埋め込みプレイヤーを表示するプラグインクラスです。"
      },
      {
        type: "table",
        titleAs: "h3",
        title: "Constructor",
        subtitle: "1st arg options of the constructor",
        content: {
          headers: [
            {
              style: { width: "25%" },
              content: "名前"
            },
            {
              style: { width: "15%" },
              content: "型"
            },
            {
              style: { width: "10%" },
              content: "必須"
            },
            {
              style: { width: "10%" },
              content: "初期値"
            },
            {
              style: { width: "30%" },
              content: "説明"
            }
          ],
          bodies: [
            {
              cells: [
                {
                  content: (
                    <code>element</code>
                  )
                },
                {
                  content: "string | HTMLElement"
                },
                {
                  content: "true"
                },
                {
                  content: "null"
                },
                {
                  content: "埋め込み先の DOM 要素を指定します。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>widget</code>
                  )
                },
                {
                  content: "number"
                },
                {
                  content: ""
                },
                {
                  content: "256"
                },
                {
                  content: "外部埋め込みプレイヤーの横幅です。この値を設定した場合、後述する横幅に関するオプションが自動的に計算されます。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>height</code>
                  )
                },
                {
                  content: "number"
                },
                {
                  content: ""
                },
                {
                  content: "100"
                },
                {
                  content: "外部埋め込みプレイヤーの縦幅です。この値を設定した場合、後述する縦幅に関するオプションが自動的に計算されます。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>responsive</code>
                  )
                },
                {
                  content: "boolean"
                },
                {
                  content: ""
                },
                {
                  content: "false"
                },
                {
                  content: "埋め込み先の親要素サイズに応じて自動的にリサイズします。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>showController</code>
                  )
                },
                {
                  content: "boolean"
                },
                {
                  content: ""
                },
                {
                  content: "true"
                },
                {
                  content: "操作に関する情報を表示します。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>showOriginalSiteLink</code>
                  )
                },
                {
                  content: "boolean"
                },
                {
                  content: ""
                },
                {
                  content: "true"
                },
                {
                  content: "配信サイトに関する情報を表示します。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>showMusicMap</code>
                  )
                },
                {
                  content: "boolean"
                },
                {
                  content: ""
                },
                {
                  content: "true"
                },
                {
                  content: "音楽地図に関する情報を表示します。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>showSongleJpSiteLink</code>
                  )
                },
                {
                  content: "boolean"
                },
                {
                  content: ""
                },
                {
                  content: "true"
                },
                {
                  content: "訂正サイトに関する情報を表示します。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>controllerWidth</code>
                  )
                },
                {
                  content: "number"
                },
                {
                  content: ""
                },
                {
                  content: "256"
                },
                {
                  content: "操作に関するインタフェースの横幅です。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>controllerHeight</code>
                  )
                },
                {
                  content: "number"
                },
                {
                  content: ""
                },
                {
                  content: "30"
                },
                {
                  content: "操作に関するインタフェースの縦幅です。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>controllerMouseEnterColor</code>
                  )
                },
                {
                  content: "string"
                },
                {
                  content: ""
                },
                {
                  content: "#e17"
                },
                {
                  content: "操作ボタンにマウスポインタが触れている場合のカラー設定です。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>controllerMouseLeaveColor</code>
                  )
                },
                {
                  content: "string"
                },
                {
                  content: ""
                },
                {
                  content: "#222"
                },
                {
                  content: "操作ボタンにマウスポインタが触れてない場合のカラー設定です。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>language</code>
                  )
                },
                {
                  content: "string"
                },
                {
                  content: ""
                },
                {
                  content: "auto"
                },
                {
                  content: "表示する言語です。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>musicMapWidth</code>
                  )
                },
                {
                  content: "number"
                },
                {
                  content: ""
                },
                {
                  content: "256"
                },
                {
                  content: "音楽地図に関するインタフェースの横幅です。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>musicMapHeight</code>
                  )
                },
                {
                  content: "number"
                },
                {
                  content: ""
                },
                {
                  content: "40"
                },
                {
                  content: "音楽地図に関するインタフェースの縦幅です。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>musicMapOuterBackgroundColor</code>
                  )
                },
                {
                  content: "string"
                },
                {
                  content: ""
                },
                {
                  content: "#999"
                },
                {
                  content: "音楽地図に関するインタフェースのカラーです。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>musicMapInnerBackgroundColor</code>
                  )
                },
                {
                  content: "string"
                },
                {
                  content: ""
                },
                {
                  content: "#eee"
                },
                {
                  content: "音楽地図に関するインタフェースのカラーです。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>musicMapChorusSectionColor</code>
                  )
                },
                {
                  content: "string"
                },
                {
                  content: ""
                },
                {
                  content: "#f84"
                },
                {
                  content: "音楽地図に関するインタフェースの繰り返し区間に関するカラーです。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>musicMapRepeatedSectionColor</code>
                  )
                },
                {
                  content: "string"
                },
                {
                  content: ""
                },
                {
                  content: "#48f"
                },
                {
                  content: "音楽地図に関するインタフェースの繰り返し区間に関するカラーです。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>musicMapCursorColor</code>
                  )
                },
                {
                  content: "string"
                },
                {
                  content: ""
                },
                {
                  content: "#e17"
                },
                {
                  content: "音楽地図に関するインタフェースの再生位置に関するカラーです。"
                }
              ]
            }
          ]
        }
      },
      {
        type: "code",
        content: `
          const plugin =
            new Songle.Plugin.SongleWidget({ element: "#sw" });

          player.addPlugin(plugin);
        `
      },
      {
        type: "text",
        titleAs: "h3",
        title: "埋め込み例",
        content: (
          <ul>
            <li>
              <a href="//jsfiddle.net/7n4sbrve" target="_">通常埋め込み</a>
            </li>
            <li>
              <a href="//jsfiddle.net/zcwfnmde" target="_">再生停止のみ</a>
            </li>
            <li>
              <a href="//jsfiddle.net/uoy6jcr8" target="_">音楽地図のみ</a>
            </li>
            <li>
              <a href="//jsfiddle.net/a7p4b6ht" target="_">音楽地図のみ（ミニ表示）</a>
            </li>
          </ul>
        )
      }
    ],
    methods: [
      {
        name: "render",
        type: "void",
        contents: [
          {
            type: "text",
            content: "明示的に再描画を実行します。"
          }
        ]
      }
    ]
  };
}

const modules = PluginSongleWidgetClassRef;

export default modules;
