//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Button, Grid, Icon, Image } from "semantic-ui-react";

import ArticleTemplate from "../../../App/components/AppPage/ArticleTemplate";

import ImgOverview1 from "../../../../../assets/document_module/overview/overview1";
import ImgOverview2 from "../../../../../assets/document_module/overview/overview2";
import ImgOverview3 from "../../../../../assets/document_module/overview/overview3en";

/**
 * @class
 */
class HowToUse extends ArticleTemplate
{
  static defaultProps = {
    articles: [
      {
        type: "text",
        anchor: "section4",
        titleAs: "h2",
        titleEn: "Use cases",
        titleJa: "利用事例",
        contentEn: (
          <Image
            centered
            size="huge"
            src={ImgOverview3} />
        ),
        contentJa: (
          <Image
            centered
            size="huge"
            src={ImgOverview3} />
        )
      },
      {
        type: "text",
        contentEn: (
          <div>
            <p>
              Songle Sync does not need dedicated devices nor preinstalled applications but only a standard web browser.
              Smartphones and tablet devices can be synchronized to a musical piece for music-driven multimedia performances.
            </p>
            <p>
              There are an increasing number and variety of microcontrollers and tiny computers that can connect to the Internet.
              They can be used to build various kinds of Internet of Things (IoT) devices, including but not limited to smart light bulbs, robots, and remote-controllable curtains.
              Songle Sync can control not only smart devices but also such IoT devices and allow a heterogeneous hardware setup.
              The heterogeneous setup as a whole creates a unique scenery synchronized with music and provides an immersive music listening experience to people in the room.
            </p>
          </div>
        ),
        contentJa: (
          <p>
            本システムは一般的なウェブブラウザから利用することができるため、スマートフォン・タブレットのように多種多様な機器を連動させることができます。また、スマートフォン・タブレットに限らず次に示す利用事例が既にサンプルプログラムとして開発されており、これらすべての利用事例に基づいて１００台以上の機器を同時に制御することが可能なことを確認しています。
          </p>
        )
      },
      {
        titleAs: "h3",
        titleEn: null,
        titleJa: "画面表示",
        type: "text",
        contentEn: null,
        contentJa: (
          <p>
            パソコン・ノートパソコン・スマートフォン・タブレットなどの汎用機器を用いて、スクリーン上で音楽に連動したアニメーションを表示しました。
          </p>
        )
      },
      {
        titleAs: "h3",
        titleEn: null,
        titleJa: "空間演出",
        type: "text",
        contentEn: null,
        contentJa: (
          <p>
            IoT 化した照明機器や発光ダイオードを内蔵した光る生活雑貨（花瓶・キャンドル）などを用いて、室内環境を音楽に連動して変化させることができました。
          </p>
        )
      },
      {
        titleAs: "h3",
        titleEn: null,
        titleJa: "ファッション制御",
        type: "text",
        contentEn: null,
        contentJa: (
          <p>
            IoT 化した発光ダイオードを装着・内蔵した光る衣装や光る腕輪を用いて、音楽に連動した発光制御ができました。
          </p>
        )
      },
      {
        titleAs: "h3",
        titleEn: null,
        titleJa: "メカ制御",
        type: "text",
        contentEn: null,
        contentJa: (
          <p>
            IoT 化した小型ロボットや電動カーテンを用いて、音楽に連動させながらロボットを動かしたり、カーテンを開閉することができました。
          </p>
        )
      }
    ]
  }
}

const modules = HowToUse;

export default modules;
