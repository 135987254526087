//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Route } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";

import AppMenu from "../components/AppMenu";

import TopPage from "../../Top/pages/TopPage";
import SignInPage from "../../User/pages/SignInPage";
import SignUpPage from "../../User/pages/SignUpPage";
import UserPage from "../../User/pages/UserPage";
import UserAppIndexPage from "../../User/pages/UserAppIndexPage";
import RestPage from "../../Overview/pages/RestPage";
import SyncPage from "../../Overview/pages/SyncPage";
import WidgetPage from "../../Overview/pages/WidgetPage";
import QuickCSharpAPIPage from "../../Quick/pages/QuickCSharpAPIPage"
import QuickJavaScriptAPIPage from "../../Quick/pages/QuickJavaScriptAPIPage";
import QuickRubyAPIPage from "../../Quick/pages/QuickRubyAPIPage";
import ReferCSharpAPIPage from "../../Refer/pages/ReferCSharpAPIPage";
import ReferJavaScriptAPIPage from "../../Refer/pages/ReferJavaScriptAPIPage";
import ReferRubyAPIPage from "../../Refer/pages/ReferRubyAPIPage";
import PrivacyPolicyPage from "../../About/pages/PrivacyPolicyPage";
import TermsOfUsePage from "../../About/pages/TermsOfUsePage";

/**
 * @const
 */
const AppPage = (props) => {
  return (
    <Router>
      <AppMenu {...props} />
      <Route
        exact
        path="/"
        render={(routeProps) => <TopPage {...props} />} />
      <Route
        exact
        path="/u/sign_up"
        render={(routeProps) => <SignUpPage {...props} />} />
      <Route
        exact
        path="/u/sign_in"
        render={(routeProps) => <SignInPage {...props} />} />
      <Route
        exact
        path="/u"
        render={(routeProps) => <UserPage {...props} />} />
      <Route
        exact
        path="/u/apps"
        render={(routeProps) => <UserAppIndexPage {...props} />} />
      <Route
        exact
        path="/rest"
        render={(routeProps) => <RestPage {...props} />} />
      <Route
        exact
        path="/sync"
        render={(routeProps) => <SyncPage {...props} />} />
      <Route
        exact
        path="/widget"
        render={(routeProps) => <WidgetPage {...props} />} />
      <Route
        exact
        path="/quickstart/csharp"
        render={(routeProps) => <QuickCSharpAPIPage {...props} />} />
      <Route
        exact
        path="/quickstart/javascript"
        render={(routeProps) => <QuickJavaScriptAPIPage {...props} />} />
      <Route
        exact
        path="/quickstart/ruby"
        render={(routeProps) => <QuickRubyAPIPage {...props} />} />
      <Route
        exact
        path="/references/csharp"
        render={(routeProps) => <ReferCSharpAPIPage {...props} />} />
      <Route
        exact
        path="/references/javascript"
        render={(routeProps) => <ReferJavaScriptAPIPage {...props} />} />
      <Route
        exact
        path="/references/ruby"
        render={(routeProps) => <ReferRubyAPIPage {...props} />} />
      <Route
        exact
        path="/privacy_policy"
        render={(routeProps) => <PrivacyPolicyPage {...props} />} />
      <Route
        exact
        path="/terms_of_use"
        render={(routeProps) => <TermsOfUsePage {...props} />} />
    </Router>
  )
}

const modules = AppPage;

export default modules;
