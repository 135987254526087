//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import ReactOnRails from "react-on-rails";

import "semantic-ui-css/semantic.min.css";
import "../../stylesheet/application.sass";

import AppPage from "../bundles/App/pages/AppPage";

ReactOnRails.register({
  AppPage
});
