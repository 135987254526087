//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Icon, List } from "semantic-ui-react";

import ArticleTemplate from "../../../App/components/AppPage/ArticleTemplate";

/**
 * @class
 */
class Article extends ArticleTemplate
{
  static defaultProps = {
    articles: [
      {
        type: "text",
        title: "本利用規約について",
        titleAs: "h3",
        content: (
          <p>
            本サービス利用規約（以下「本規約」といいます）は、国立研究開発法人産業技術総合研究所　情報技術研究部門　メディアインタラクション研究グループ（承継先を含み、以下「当グループ」といいます）が研究開発および実証実験を進めている「Songle」、「Songle API（Songle Widget及びSongle Syncを含む）」（後継、関連、類似及びAPIその他の派生サービスを含み、以下「本サービス」といいます）を、ユーザーが利用するにあたっての諸条件について規定します。ユーザーは本サービスを利用する前に、必ず本規約を確認の上、同意してください。
          </p>
        )
      },
      {
        type: "text",
        title: "提供目的",
        titleAs: "h3",
        content: (
          <p>

          </p>
        )
      },
      {
        type: "text",
        title: "ユーザー",
        titleAs: "h3",
        content: (
          <p>

          </p>
        )
      },
      {
        type: "text",
        title: "サービスの利用",
        titleAs: "h3",
        content: (
          <p>

          </p>
        )
      },
      {
        type: "text",
        title: "ユーザーの遵守事項",
        titleAs: "h3",
        content: (
          <p>

          </p>
        )
      },
      {
        type: "text",
        title: "帰属権利",
        titleAs: "h3",
        content: (
          <p>

          </p>
        )
      },
      {
        type: "text",
        title: "ユーザーの同意事項",
        titleAs: "h3",
        content: (
          <p>

          </p>
        )
      },
      {
        type: "text",
        title: "クレジット",
        titleAs: "h3",
        content: (
          <p>

          </p>
        )
      },
      {
        type: "text",
        title: "非商用・商用利用",
        titleAs: "h3",
        content: (
          <p>

          </p>
        )
      },
      {
        type: "text",
        title: "禁止事項",
        titleAs: "h3",
        content: (
          <p>

          </p>
        )
      },
      {
        type: "text",
        title: "利用制限",
        titleAs: "h3",
        content: (
          <p>

          </p>
        )
      },
      {
        type: "text",
        title: "更新の義務",
        titleAs: "h3",
        content: (
          <p>

          </p>
        )
      },
      {
        type: "text",
        title: "責任の制限",
        titleAs: "h3",
        content: (
          <p>

          </p>
        )
      },
      {
        type: "text",
        title: "損害賠償等",
        titleAs: "h3",
        content: (
          <p>

          </p>
        )
      },
      {
        type: "text",
        title: "反社会的勢力の排除",
        titleAs: "h3",
        content: (
          <p>

          </p>
        )
      },
      {
        type: "text",
        title: "準拠法・裁判管轄",
        titleAs: "h3",
        content: (
          <p>

          </p>
        )
      }
    ]
  };
}

const modules = Article;

export default modules;
