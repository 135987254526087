//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import ArticleTemplate from "../../../App/components/AppPage/ArticleTemplate";

/**
 * @class
 */
class Example2 extends ArticleTemplate
{
  static defaultProps = {
    articles: [
      {
        type: "text",
        anchor: "section4",
        titleAs: "h2",
        title: "サンプルプログラム２",
        content: (
          <p>
            次のサンプルプログラムは、サンプルプログラム１を大規模音楽連動制御に対応させたプログラムです。<br/>
            取得したトークンに書き換えた後、複数のブラウザからマスターとスレーブを実行して、大規模音楽連動制御のイメージを掴んでみましょう。
          </p>
        )
      },
      {
        type: "text",
        titleAs: "h3",
        title: "ウェブブラウザ / マスター",
        content: (
          <iframe frameBorder="0" height="220" src="//jsfiddle.net/mjtva8yb/embedded/js,html,css" width="100%" />
        )
      },
      {
        type: "text",
        titleAs: "h3",
        title: "ウェブブラウザ / スレーブ",
        content: (
          <iframe frameBorder="0" height="200" src="//jsfiddle.net/tbjof4xz/embedded/js,html,css" width="100%" />
        )
      },
      {
        type: "text",
        content: (
          <p>
            次のサンプルプログラムは Node.js 上で動作するスレーブ（ヘッドレスモード）のサンプルです。
          </p>
        )
      },
      {
        type: "note",
        title: "ヘッドレスモードについて",
        content: (
          <p>
            ヘッドレスモードとは、音源再生の機能を持たない端末などで大規模音楽連動制御を使うための仕組みことです。スレーブのプログラムを記述する際にメディアの設定を省略した場合、自動的にヘッドレスモードがになります。ヘッドレスモードは、明示的に指定することでマスターをヘッドレスモードで実行することもできます。
          </p>
        )
      },
      {
        type: "code",
        titleAs: "h3",
        title: "Node.js / スレーブ",
        language: "javascript",
        content: `
          var Songle = require("songle-api");

          var player =
            new Songle.SyncPlayer({
              accessToken: "YOUR-ACCESS-TOKEN-HERE", // please edit your access token
            });

          player.addPlugin(new Songle.Plugin.Beat());

          player.on("beatPlay",
            function(ev) {
              switch(ev.data.beat.position) {
                case 1:
                  console.log("1st beat !!");
                  break;

                case 2:
                  console.log("2nd beat !!");
                  break;

                case 3:
                  console.log("3rd beat !!")
                  break;

                case 4:
                  console.log("4th beat !!");
                  break;
              }
            });
        `
      },
      {
        type: "text",
        titleAs: "h3",
        title: "より詳細なサンプルプログラム",
        content: (
          <p>
            Songle Sync API を使ったより詳細なサンプルプログラムは <a href="//github.com/SongleJp" target="_">GitHub 公式リポジトリ</a>から探すことができます。
          </p>
        )
      }
    ]
  }
}

const modules = Example2;

export default modules;
