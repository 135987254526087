//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { List, Segment } from "semantic-ui-react";

import AppPageTemplate from "../../App/components/AppPageTemplate";

import Abstract from "../components/SyncPage/Abstract";
import News from "../components/SyncPage/News";
import HowToUse from "../components/SyncPage/HowToUse";
import UseCase from "../components/SyncPage/UseCase";
import Credit from "../components/SyncPage/Credit";

/**
 * @class
 */
class SyncPage extends AppPageTemplate
{
  static defaultProps = {
    title: "Songle Sync API"
  };

  /**
   * @function
   */
  renderTocs()
  {
    const { locale } = this.props;

    return (
      <Segment className="square">
        <List size="small">
          <List.Item>
            <List.Icon name="list" />
            <List.Content>
              <List.Header>
                {
                  locale === "en" ?
                    "Table of contents"
                  :
                    "目次"
                }
              </List.Header>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.List>
                <List.Item
                  content={locale === "en" ? "What is Songle Sync?" : "概要"}
                  as="a"
                  href="#section1" />
                <List.Item
                  content={locale === "en" ? "News" : "ニュース"}
                  as="a"
                  href="#section2" />
                <List.Item
                  content={locale === "en" ? "How to use" : "使い方"}
                  as="a"
                  href="#section3" />
                <List.Item
                  content={locale === "en" ? "Use cases" : "利用事例"}
                  as="a"
                  href="#section4" />
                <List.Item
                  content={locale === "en" ? "Credit" : "クレジット"}
                  as="a"
                  href="#section5" />
              </List.List>
            </List.Content>
          </List.Item>
        </List>
      </Segment>
    );
  }

  /**
   * @function
   */
  renderContent()
  {
    const { locale } = this.props;

    return (
      <div>
        <Segment className="square">
          <Abstract locale={locale} />
        </Segment>
        <Segment className="square">
          <News locale={locale} />
        </Segment>
        <Segment className="square">
          <HowToUse locale={locale} />
        </Segment>
        <Segment className="square">
          <UseCase locale={locale} />
        </Segment>
        <Segment className="square">
          <Credit locale={locale} />
        </Segment>
      </div>
    );
  }
}

const modules = SyncPage;

export default modules;
