//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Header, Segment } from "semantic-ui-react";

import ArticleTemplate from "../../App/components/AppPage/ArticleTemplate";
import ClassAttrToC from "./ClassRef/ClassAttrToC";
import ClassAttrRef from "./ClassRef/ClassAttrRef";

/**
 * @class
 */
class ClassRef extends React.Component
{
  static defaultProps = {
    name: "",
    type: "",
    contents: [
    ]
  };

  /**
   * @function
   * @override
   */
  render()
  {
    const { name, type, contents } = this.props;

    return (
      <Segment className="square" id={name}>
        <Header as="h2">
          <code>{type ? `${name}: ${type}` : `${name}`}</code>
        </Header>
        <ArticleTemplate articles={contents} />
        <ClassAttrToC.List>
          <ClassAttrToC.Item
            anchorPrefix={name}
            title="Constants"
            subtitle="Static constant variables of this class"
            contents={this.props.constants} />
          <ClassAttrToC.Item
            anchorPrefix={name}
            title="Static properties (Getter/Setter)"
            subtitle="Static properties of this class"
            contents={this.props.staticProperties} />
          <ClassAttrToC.Item
            anchorPrefix={name}
            title="Static properties (Getter)"
            subtitle="Get only static properties of this class"
            contents={this.props.staticGetterProperties} />
          <ClassAttrToC.Item
            anchorPrefix={name}
            title="Static properties (Setter)"
            subtitle="Set only static properties of this class"
            contents={this.props.staticSetterProperties} />
          <ClassAttrToC.Item
            anchorPrefix={name}
            title="Static methods"
            subtitle="Static methods of this class"
            contents={this.props.staticMethods} />
          <ClassAttrToC.Item
            anchorPrefix={name}
            title="Properties (Getter/Setter)"
            subtitle="Properties of this class instance"
            contents={this.props.properties} />
          <ClassAttrToC.Item
            anchorPrefix={name}
            title="Properties (Getter)"
            subtitle="Get only properties of this class instance"
            contents={this.props.getterProperties} />
          <ClassAttrToC.Item
            anchorPrefix={name}
            title="Properties (Setter)"
            subtitle="Set only properties of this class instance"
            contents={this.props.setterProperties} />
          <ClassAttrToC.Item
            anchorPrefix={name}
            title="Methods"
            subtitle="Methods of this class instance"
            contents={this.props.methods} />
          <ClassAttrToC.Item
            anchorPrefix={name}
            title="Events"
            subtitle="Related events of this class"
            contents={this.props.events} />
        </ClassAttrToC.List>
        <ClassAttrRef
          anchorPrefix={name}
          contents={this.props.constants} />
        <ClassAttrRef
          anchorPrefix={name}
          contents={this.props.staticProperties} />
        <ClassAttrRef
          anchorPrefix={name}
          contents={this.props.staticGetterProperties} />
        <ClassAttrRef
          anchorPrefix={name}
          contents={this.props.staticSetterProperties} />
        <ClassAttrRef
          anchorPrefix={name}
          contents={this.props.staticMethods} />
        <ClassAttrRef
          anchorPrefix={name}
          contents={this.props.properties} />
        <ClassAttrRef
          anchorPrefix={name}
          contents={this.props.getterProperties} />
        <ClassAttrRef
          anchorPrefix={name}
          contents={this.props.setterProperties} />
        <ClassAttrRef
          anchorPrefix={name}
          contents={this.props.methods} />
        <ClassAttrRef
          anchorPrefix={name}
          contents={this.props.events} />
      </Segment>
    )
  }
}

const modules = ClassRef;

export default modules;
