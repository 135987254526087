//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Icon, List } from "semantic-ui-react";

import ArticleTemplate from "../../../App/components/AppPage/ArticleTemplate";

/**
 * @class
 */
class Article extends ArticleTemplate
{
  static defaultProps = {
    articles: [
      {
        type: "text",
        title: "個人情報にかかる保護方針",
        titleAs: "h3",
        content: (
          <p>
            国立研究開発法人産業技術総合研究所　情報技術研究部門　メディアインタラクション研究グループ（以下「当グループ」といいます）は、個人情報の重要性を認識し、個人情報を保護することが社会的責務であると考え、個人情報に関する法令及び社内規程等を遵守し、当グループで取扱う個人情報の取得、利用、管理を適正に行います。
          </p>
        )
      },
      {
        type: "text",
        title: "適用範囲",
        titleAs: "h3",
        content: (
          <p>
            本プライバシーポリシーは、当グループが提供する各種サービス（API の提供を含み、以下「本サービス」といいます）及び本サービスに関連した研究において、ユーザーの個人情報を取扱う際に、当グループが従うべき方針を示しています。
          </p>
        )
      },
      {
        type: "text",
        title: "個人情報の取得",
        titleAs: "h3",
        content: (
          <div>
            <List style={{ marginLeft: "24px" }}>
              <List.Item>
                <List.Content>
                  <List.Header content="個人情報" />
                  <List.List>
                    <List.Item>
                      当グループは、個人情報として、ユーザーのメールアドレスを取り扱います。
                    </List.Item>
                  </List.List>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header content="個人情報にあたらない情報" />
                  <List.List>
                    <List.Item>
                      クッキー、IPアドレス等特定の個人を識別できない情報は、個人情報とは扱わないものとします。<br/>
                      ただし、これらの情報が他の情報と一体となって容易に個人を識別できる場合には、その限りにおいて当該情報も個人情報として扱います。
                    </List.Item>
                  </List.List>
                </List.Content>
              </List.Item>
            </List>
          </div>
        )
      },
      {
        type: "text",
        title: "個人情報の利用目的",
        titleAs: "h3",
        content: (
          <div>
            <List style={{ marginLeft: "24px" }}>
              <List.Item>
                <List.Content>
                  <List.Header content="利用の範囲" />
                  <List.List>
                    <List.Item>
                      <p>
                        当グループが保有する個人情報は、次の各号に掲げる、当グループが提供する本サービス、研究及び業務の利用目的の達成に必要な範囲で、当グループ及びその業務受託者が利用いたします。
                      </p>
                      <List
                        ordered
                        style={{ marginLeft: "24px" }}>
                        <List.Item content="本サービスの提供や機能改善に向けた研究開発のため" />
                        <List.Item content="本サービスの利用に伴う連絡のため" />
                        <List.Item content="ユーザーの承諾・申し込みに基づく、本サービスに関連する企業、当グループの提携企業又は団体等に対して個人情報を提供するため" />
                        <List.Item content="新規サービスの開発及びマーケティングのため" />
                        <List.Item content="本サービスに関するご意見、お問い合わせ内容の確認又は回答のため" />
                        <List.Item content="本プライバシーポリシーその他当グループとの合意内容に対する違反、本サービスの違法な利用又は不適切な利用の有無を調査し、対処するため" />
                      </List>
                    </List.Item>
                  </List.List>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header content="利用目的の変更" />
                  <List.List>
                    <List.Item>
                      当研究所が利用目的を変更する場合には、変更された利用目的について、ユーザーに通知し、又は公表します。
                    </List.Item>
                  </List.List>
                </List.Content>
              </List.Item>
            </List>
          </div>
        )
      },
      {
        type: "text",
        title: "個人情報の管理",
        titleAs: "h3",
        content: (
          <p>
            当グループは、ユーザーからご提供いただいた情報の管理について、個人情報保護法の規定に従い適正に管理すべく努めます。
          </p>
        )
      },
      {
        type: "text",
        title: "第三者提供の制限",
        titleAs: "h3",
        content: (
          <p>
            当グループは、法令で認められる場合又は本規約で定める場合を除くほか、あらかじめユーザーの同意を得ないで、個人情報を第三者に提供しません。<br/>
            個人情報を第三者に提供する場合には、ユーザーから同意を得ることとします。
          </p>
        )
      },
      {
        type: "text",
        title: "お問い合わせ先",
        titleAs: "h3",
        content: (
          <p>
            本サービス又は個人情報の取扱いに関しての問い合わせは、下記の窓口にて電子メールで受け付けております。<br/>
            本サービスお問い合わせ窓口 <Icon name="mail" /><a href="mailto:songle-ml [at] aist.go.jp">songle-ml [at] aist.go.jp</a>
          </p>
        )
      },
      {
        type: "text",
        title: "本プライバシーポリシーの変更",
        titleAs: "h3",
        content: (
          <p>
            当グループは、法令変更への対応の必要性及び事業上の必要性等に応じて、本プライバシーポリシーを変更及び改正する場合があります。<br/>
            このウェブサイト上に掲載される最新の本方針の内容をご確認ください。
          </p>
        )
      },
      {
        type: "text",
        title: "改訂",
        titleAs: "h3",
        content: (
          <p>
            平成 29 年 8 月 2 日策定
          </p>
        )
      },
    ]
  };
}

const modules = Article;

export default modules;
