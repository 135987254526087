//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { List, Segment } from "semantic-ui-react";

import AppPageTemplate from "../../App/components/AppPageTemplate";

import Article from "../components/PrivacyPolicyPage/Article";

/**
 * @class
 */
class PrivacyPolicyPage extends AppPageTemplate
{
  static defaultProps = {
    title: "プライバシーポリシー",
    tocs: (
      <Segment className="square">
        <List size="small">
          <List.Item>
            <List.Icon name="list" />
            <List.Content>
              <List.Header>
                目次
              </List.Header>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.List>
                <List.Item
                  content="個人情報にかかる保護方針"
                  as="a"
                  href="#section1" />
                <List.Item
                  content="適用範囲"
                  as="a"
                  href="#section2" />
                <List.Item
                  content="個人情報の取得"
                  as="a"
                  href="#section3" />
                <List.Item
                  content="個人情報の利用目的"
                  as="a"
                  href="#section4" />
                <List.Item
                  content="個人情報の管理"
                  as="a"
                  href="#section5" />
                <List.Item
                  content="第三者提供の制限"
                  as="a"
                  href="#section6" />
                <List.Item
                  content="お問い合わせ先"
                  as="a"
                  href="#section7" />
                <List.Item
                  content="本プライバシーポリシーの変更"
                  as="a"
                  href="#section8" />
                <List.Item
                  content="改訂"
                  as="a"
                  href="#section9" />
              </List.List>
            </List.Content>
          </List.Item>
        </List>
      </Segment>
    ),
    content: (
      <Segment className="square">
        <Article autoAnchor />
      </Segment>
    )
  };
}

const modules = PrivacyPolicyPage;

export default modules;
