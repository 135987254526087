//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import ClassRef from "./ClassRef";

/**
 * @class
 */
class MediaYtVideoClassRef extends ClassRef
{
  static defaultProps = {
    name: "Media.YtVideo",
    type: "class",
    contents: [
      {
        type: "text",
        content: "外部の動画サービスの外部埋め込みプレーヤを利用するメディアクラスです。"
      },
      {
        type: "code",
        content: `
          const media =
            new Songle.Media.YtVideo(ytVideoInstance);

          player.useMedia(media);
        `
      }
    ]
  };
}

const modules = MediaYtVideoClassRef;

export default modules;
