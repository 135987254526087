//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Button, Divider, Grid, Header, Icon, Segment } from "semantic-ui-react";

import SensitiveInput from "../SensitiveInput";

import Stage from "../Stage";

import UserApp from "../UserApp";

/**
 * @class
 */
class UserAppContent extends React.Component
{
  /**
   * @constructor
   */
  constructor(
    props
  )
  {
    super(props);

    this.state = {
      updateFormIsVisible: false
    };
  }

  /**
   * @function
   */
  deleteApp = () => {
    const { app } = this.props;

    return new Promise((ready, error) => {
      const request =
        fetch(`/api/v2/apps/${app.id}.json`, {
          method: "DELETE",
          headers: ReactOnRails.authenticityHeaders({
            "Content-Type": "application/json"
          }),
          body: JSON.stringify({
            syntax: "camelcase"
          })
        });

      request
        .then((response) => response.json())
        .then((response) => {
          if(response.meta.status != 200)
          {
            error(response);
          }
          else
          {
            ready(response);
          }
        });
    });
  }

  /**
   * @function
   */
  handleEditButtonClick = () => {
    this.setState({
      updateFormIsVisible: true
    });
  }

  /**
   * @function
   */
  handleAppUpdate = (ev) => {
    const { app, onUpdate } = this.props;

    this.setState({
      updateFormIsVisible: false
    });

    onUpdate &&
    onUpdate(ev, app);
  }

  /**
   * @function
   */
  handleAppDelete = (ev) => {
    const { app, onDelete } = this.props;

    this.setState({
      updateFormIsVisible: false
    });

    if(confirm("この操作を実行してもよろしいですか？")) {
      this.deleteApp()
        .then((response) => {
          onDelete &&
          onDelete(ev, { app: response.data });
        });
    }
  }

  /**
   * @function
   * @override
   */
  render()
  {
    const { app } = this.props;

    return (
      <Segment>
        <Grid>
          <Grid.Row columns="equal">
            <Grid.Column>
              <Header as="h2">
                <Header.Content>
                  {app.attributes.name}
                </Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Button
                color="pink"
                onClick={this.handleEditButtonClick}>
                <Icon name="edit" />
                編集
              </Button>
            </Grid.Column>
          </Grid.Row>
          {
            (() => {
              const { updateFormIsVisible } = this.state;

              if(!updateFormIsVisible) {
                return;
              }

              return (
                <Grid.Row>
                  <Grid.Column>
                    <UserApp.Form
                      app={app}
                      onSubmit={this.handleAppUpdate} />
                  </Grid.Column>
                </Grid.Row>
              );
            })()
          }
          <Grid.Row>
            <Grid.Column>
              <Grid
                columns="equal"
                divided>
                <Grid.Column>
                  <Header as="h4">
                    概要
                  </Header>
                  <p>
                    {app.attributes.description}
                  </p>
                </Grid.Column>
                <Grid.Column>
                  <Header as="h4">
                    <Header.Content>
                      ステージ数
                    </Header.Content>
                  </Header>
                  <p style={{ fontSize: "48px", textAlign: "center" }}>
                    {app.attributes.stageCount}
                  </p>
                </Grid.Column>
                <Grid.Column>
                  <Header as="h4">
                    <Header.Content>
                      マスターの接続数
                    </Header.Content>
                  </Header>
                  <p style={{ fontSize: "48px", textAlign: "center" }}>
                    {app.attributes.stageMasterNodeCount}
                  </p>
                </Grid.Column>
                <Grid.Column>
                  <Header as="h4">
                    <Header.Content>
                      スレーブの接続数
                    </Header.Content>
                  </Header>
                  <p style={{ fontSize: "48px", textAlign: "center" }}>
                    {app.attributes.stageActiveNodeCount}
                  </p>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as="h3">
                アプリトークン
              </Header>
              <SensitiveInput value={app.attributes.token} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button
                color="red"
                onClick={this.handleAppDelete}>
                <Icon name="trash" />
                このアプリを削除
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Divider />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Stage.Explorer app={app} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

const modules = UserAppContent;

export default modules;
