//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import ClassRef from "./ClassRef";

/**
 * @class
 */
class PluginSuperClassRef extends ClassRef
{
  static defaultProps = {
    name: "Plugin.SuperClass",
    type: "abstract class",
    contents: [
      {
        type: "text",
        content: (
          <p>
            プラグインクラスのスーパークラスです。<br/>
            全てのプラグインクラスは、このスーパークラスのメソッド・プロパティを呼び出すことができます。
          </p>
        )
      }
    ],
    constants: [
      {
        name: "Name",
        type: "string",
        contents: [
          {
            type: "text",
            content: (
              <p>
                プラグインクラスに設定された名称の定数です。<br/>
                この値は継承先のプラグインクラスによって変化することがあります。
              </p>
            )
          }
        ]
      },
      {
        name: "Type",
        type: "string",
        contents: [
          {
            type: "text",
            content: (
              <p>
                プラグインクラスに設定された種類の定数です。<br/>
                この値は継承先のプラグインクラスによって変化することがあります。
              </p>
            )
          }
        ]
      }
    ],
    getterProperties: [
      {
        name: "dispatcherName",
        type: "string",
        contents: [
          {
            type: "text",
            content: (
              <p>
                プラグインクラスのインスタンスに設定された名称です。<br/>
                この値は継承先のプラグインクラスによって変化することがあります。
              </p>
            )
          }
        ]
      },
      {
        name: "dispatcherType",
        type: "string",
        contents: [
          {
            type: "text",
            content: (
              <p>
                プラグインクラスのインスタンスに設定された種類です。<br/>
                この値は継承先のプラグインクラスによって変化することがあります。
              </p>
            )
          }
        ]
      }
    ]
  };
}

const modules = PluginSuperClassRef;

export default modules;
