//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import ClassRef from "./ClassRef";

/**
 * @class
 */
class MediaHTMLMediaElementClassRef extends ClassRef
{
  static defaultProps = {
    name: "Media.HTMLMediaElement",
    type: "class",
    contents: [
      {
        type: "text",
        content: "マルチメディアファイルを利用するメディアクラスです。"
      },
      {
        type: "code",
        content: `
          const media =
            new Songle.Media.HTMLMediaElement(audioElement);

          player.useMedia(media);
        `
      }
    ]
  };
}

const modules = MediaHTMLMediaElementClassRef;

export default modules;
