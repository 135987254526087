//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Segment } from "semantic-ui-react";

import AppPageTemplate from "../../App/components/AppPageTemplate";

import UserApp from "../components/UserAppIndexPage/UserApp";

/**
 * @class
 */
class UserAppIndexPage extends AppPageTemplate
{
  static defaultProps = {
    titleIcon: "th",
    title: "アプリ",
    subtitle: "あなたのアプリケーション",
    showTocs: false,
    content: (
      <Segment className="square">
        <UserApp.Explorer />
      </Segment>
    )
  };
}

const modules = UserAppIndexPage;

export default modules;
