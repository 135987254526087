//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { List, Segment } from "semantic-ui-react";

import AppPageTemplate from "../../App/components/AppPageTemplate";

/**
 * @class
 */
class QuickRubyAPIPage extends AppPageTemplate
{
  static defaultProps = {
    titleIcon: "code",
    title: "Songle Ruby API クイックスタート",
    tocs: <Segment className="square" />,
    content: <Segment className="square" />
  };
}

const modules = QuickRubyAPIPage;

export default modules;
