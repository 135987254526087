//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { List, Segment } from "semantic-ui-react";

import AppPageTemplate from "../../App/components/AppPageTemplate";

import Abstract from "../components/QuickJavaScriptAPIPage/Abstract";
import Example1 from "../components/QuickJavaScriptAPIPage/Example1";
import AbstractSongleSync from "../components/QuickJavaScriptAPIPage/AbstractSongleSync";
import Example2 from "../components/QuickJavaScriptAPIPage/Example2";

/**
 * @class
 */
class QuickJavaScriptAPIPage extends AppPageTemplate
{
  static defaultProps = {
    titleIcon: "code",
    title: "Songle JavaScript API クイックスタート",
    tocs: (
      <Segment className="square">
        <List size="small">
          <List.Item>
            <List.Icon name="list" />
            <List.Content>
              <List.Header>
                目次
              </List.Header>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.List>
                <List.Item
                  content="はじめに"
                  as="a"
                  href="#section1" />
                <List.Item
                  content="サンプルプログラム１"
                  as="a"
                  href="#section2" />
                <List.Item
                  content="大規模音楽連動制御"
                  as="a"
                  href="#section3" />
                <List.Item
                  content="サンプルプログラム２"
                  as="a"
                  href="#section4" />
              </List.List>
            </List.Content>
          </List.Item>
        </List>
      </Segment>
    ),
    content: (
      <div>
        <Segment className="square">
          <Abstract />
        </Segment>
        <Segment className="square">
          <Example1 />
        </Segment>
        <Segment className="square">
          <AbstractSongleSync />
        </Segment>
        <Segment className="square">
          <Example2 />
        </Segment>
      </div>
    )
  };
}

const modules = QuickJavaScriptAPIPage;

export default modules;
