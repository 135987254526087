//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Image } from "semantic-ui-react";

import ArticleTemplate from "../../../App/components/AppPage/ArticleTemplate";

import ImgOverview1Ja from "../../../../../assets/document_module/overview/overview1";
import ImgOverview1En from "../../../../../assets/document_module/overview/overview1en";

/**
 * @class
 */
class Abstract extends ArticleTemplate
{
  static defaultProps = {
    articles: [
      {
        type: "text",
        anchor: "section1",
        titleAs: "h2",
        titleEn: "What is a Songle Sync?",
        titleJa: "Songle Sync （ソングルシンク）とは",
        contentJa: (
          <p>
            ウェブ上の音楽の再生に合わせて多種多様な機器を同時制御することで、一体感のある演出ができる大規模音楽連動制御プラットフォームです。誰でも自分のスマートフォンやパソコン等の機器を自由自在に組み合わせて、好きな楽曲の再生に合わせて光ったり動いたりする演出を作ることができます。
          </p>
        ),
        contentEn: (
          <p>
            Songle Sync is a web-based platform that allows to play audio of songs available on the web and control various kinds of devices in synchrony with the audio playback.
            More than a hundred smartphones, tablets, personal computers, and <abbr title="Internet of Things">IoT</abbr> devices were confirmed to synchronize perfectly with the audio playback.
          </p>
        ),
      },
      {
        type: "text",
        contentEn: (
          <Image
            centered
            size="huge"
            src={ImgOverview1En} />
        ),
        contentJa: (
          <Image
            centered
            size="huge"
            src={ImgOverview1Ja} />
        )
      },
      {
        type: "text",
        contentEn: <iframe allowFullScreen frameBorder="0" width="100%" height="400" src="//www.youtube-nocookie.com/embed/dErV9oZw_uQ?controls=1" />,
        contentJa: <iframe allowFullScreen frameBorder="0" width="100%" height="400" src="//www.youtube-nocookie.com/embed/kN63Am1EpoA?controls=1" />
      }
    ]
  }
}

const modules = Abstract;

export default modules;
