//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Icon, List } from "semantic-ui-react";

/**
 * @class
 */
const Item = (
  props
) =>
{
  const { anchorPrefix, title, subtitle, contents } = props;

  if(!contents) {
    return (
      <div />
    );
  }

  return (
    <List.Item>
      <Icon name="linkify" />
      <List.Content>
        <List.Header>
          {title}
        </List.Header>
        <List.Description>
          {subtitle}
        </List.Description>
        <List.List>
          {
            contents.map((content) => {
              const { name, type } = content;

              const anchorName = `${anchorPrefix}.${name}`;

              return (
                <List.Item
                  key={anchorName}
                  as="a"
                  href={`#${anchorName}`}>
                  <code>{name}{type ? `: ${type}` : ``}</code>
                </List.Item>
              );
            })
          }
        </List.List>
      </List.Content>
    </List.Item>
  );
}

const modules = {
  List: List,
  Item: Item
};

export default modules;
