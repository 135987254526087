//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { List, Segment } from "semantic-ui-react";

import AppPageTemplate from "../../App/components/AppPageTemplate";

import WindowClassRef from "../components/WindowClassRef";
import PlayerClassRef from "../components/PlayerClassRef";
import SyncPlayerClassRef from "../components/SyncPlayerClassRef";
import WidgetPlayerClassRef from "../components/WidgetPlayerClassRef";
import MediaSuperClassRef from "../components/MediaSuperClassRef";
import MediaHeadlessClassRef from "../components/MediaHeadlessClassRef";
import MediaHTMLMediaElementClassRef from "../components/MediaHTMLMediaElementClassRef";
import MediaNnVideoClassRef from "../components/MediaNnVideoClassRef";
import MediaYtVideoClassRef from "../components/MediaYtVideoClassRef";
import PluginSuperClassRef from "../components/PluginSuperClassRef";
import PluginBeatClassRef from "../components/PluginBeatClassRef";
import PluginChordClassRef from "../components/PluginChordClassRef";
import PluginMelodyClassRef from "../components/PluginMelodyClassRef";
import PluginChorusClassRef from "../components/PluginChorusClassRef";
import PluginVariationClassRef from "../components/PluginVariationClassRef";
import PluginSongleSyncClassRef from "../components/PluginSongleSyncClassRef";
import PluginSongleWidgetClassRef from "../components/PluginSongleWidgetClassRef";
import SystemClassRef from "../components/SystemClassRef";

/**
 * @class
 */
class ReferJavaScriptAPIPage extends AppPageTemplate
{
  static defaultProps = {
    titleIcon: "book",
    title: "Songle JavaScript API リファレンス",
    tocs: (
      <Segment className="square">
        <List size="small">
          <List.Item>
            <List.Icon name="list" />
            <List.Content>
              <List.Header>
                目次
              </List.Header>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.List>
                {
                  (() => {
                    const tocs = [
                      "Window",
                      "Player",
                      "SyncPlayer",
                      "WidgetPlayer",
                      "Media.SuperClass",
                      "Media.Headless",
                      "Media.HTMLMediaElement",
                      "Media.NnVideo",
                      "Media.YtVideo",
                      "Plugin.SuperClass",
                      "Plugin.Beat",
                      "Plugin.Chord",
                      "Plugin.Melody",
                      "Plugin.Chorus",
                      "Plugin.Variation",
                      "Plugin.SongleSync",
                      "Plugin.SongleWidget",
                      "System"
                    ];

                    return tocs.map((toc) => {
                      return (
                        <List.Item
                          key={toc}
                          as="a"
                          href={`#${toc}`}
                          style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                          <code>{toc}</code>
                        </List.Item>
                      );
                    });
                  })()
                }
              </List.List>
            </List.Content>
          </List.Item>
        </List>
      </Segment>
    ),
    content: (
      <div>
        <WindowClassRef />
        <PlayerClassRef />
        <SyncPlayerClassRef />
        <WidgetPlayerClassRef />
        <MediaSuperClassRef />
        <MediaHeadlessClassRef />
        <MediaHTMLMediaElementClassRef />
        <MediaNnVideoClassRef />
        <MediaYtVideoClassRef />
        <PluginSuperClassRef />
        <PluginBeatClassRef />
        <PluginChordClassRef />
        <PluginMelodyClassRef />
        <PluginChorusClassRef />
        <PluginVariationClassRef />
        <PluginSongleSyncClassRef />
        <PluginSongleWidgetClassRef />
        <SystemClassRef />
      </div>
    )
  };
}

const modules = ReferJavaScriptAPIPage;

export default modules;
