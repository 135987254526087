//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import ClassRef from "./ClassRef";

/**
 * @class
 */
class PluginChordClassRef extends ClassRef
{
  static defaultProps = {
    name: "Plugin.Chord",
    type: "class",
    contents: [
      {
        type: "text",
        content: "音楽地図の和音を扱うプラグインクラスです。"
      },
      {
        type: "table",
        titleAs: "h3",
        title: "Constructor",
        subtitle: "1st arg options of the constructor",
        content: {
          headers: [
            {
              style: { width: "10%" },
              content: "名前"
            },
            {
              style: { width: "10%" },
              content: "型"
            },
            {
              style: { width: "10%" },
              content: "初期値"
            },
            {
              style: { width: "70%" },
              content: "説明"
            }
          ],
          bodies: [
            {
              cells: [
                {
                  content: (
                    <code>revisionId</code>
                  )
                },
                {
                  content: "number"
                },
                {
                  content: "0"
                },
                {
                  content: "使用する音楽地図のリビジョン番号です。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>offset</code>
                  )
                },
                {
                  content: "number"
                },
                {
                  content: "0"
                },
                {
                  content: "イベントのオフセット値（ミリ秒）です。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>pollingInterval</code>
                  )
                },
                {
                  content: "number"
                },
                {
                  content: "50"
                },
                {
                  content: "イベントのポーリング値（ミリ秒）です。"
                }
              ]
            }
          ]
        }
      },
      {
        type: "code",
        content: `
          player.addPlugin(new Songle.Plugin.Chord());

          player.on("chordEnter",
            function(ev) {
              // do someting ...
            });
        `
      },
      {
        title: "リビジョンについて",
        type: "note",
        content: (
          <p>
            リビジョンとは、音楽理解技術による自動解析の誤りを人間が訂正した際に残る編集履歴のことです。この番号を指定することで、アプリケーションで使用する音楽地図の訂正状態を固定することができます。これは、音楽地図が第三者により編集された場合に発生する不整合を防ぐ場合に役立ちます。リビジョン番号は、<a href="//songle.jp/songs/610565" target="_">楽曲ページ</a>の<a href="//songle.jp/songs/610565/history" target="_">編集履歴</a>より確認することができます。
          </p>
        )
      },
      {
        title: "オフセットについて",
        type: "note",
        content: (
          <p>
            オフセットでは、イベントの実行タイミングにオフセットを与えることができます。これは、アプリケーションの処理負荷などの要因によって、イベントの実行タイミングが前後にズレる場合に役立ちます。また、オフセットはイベント毎に個別に与えることもできます。
          </p>
        )
      },
      {
        type: "code",
        content: `
          player.addPlugin(new Songle.Plugin.Chord({
            offset: -100,
          });

          player.on("chordEnter",
            function(ev) {
              // this event will be run faster 100ms than the original chord event
            });

          player.on("chordEnter",
            function(ev) {
              // this event will be run faster 200ms than the original chord event
            }, { offset: -100 });

          player.on("chordEnter",
            function(ev) {
              // this event will be run faster 300ms than the original chord event
            }, { offset: -200 });
        `
      }
    ],
    constants: [
      {
        name: "ChordEnterTrapName",
        type: "string",
        contents: [
          {
            type: "text",
            content: "和音の開始で呼び出されるイベント名の定数です。"
          }
        ]
      },
      {
        name: "ChordLeaveTrapName",
        type: "string",
        contents: [
          {
            type: "text",
            content: "和音の終了で呼び出されるイベント名の定数です。"
          }
        ]
      }
    ],
    properties: [
      {
        name: "offset",
        type: "number",
        contents: [
          {
            type: "text",
            content: "イベントのオフセット値（ミリ秒）です。"
          }
        ]
      },
      {
        name: "pollingInterval",
        type: "number",
        contents: [
          {
            type: "text",
            content: "イベントのポーリング値（ミリ秒）です。"
          }
        ]
      }
    ],
    getterProperties: [
      {
        name: "revisionId",
        type: "number",
        contents: [
          {
            type: "text",
            content: "使用する音楽地図のリビジョン番号です。"
          }
        ]
      }
    ],
    events: [
      {
        name: "chordEnter",
        contents: [
          {
            type: "text",
            content: "和音の開始で呼び出されるイベントです。"
          },
          {
            type: "code",
            content: `
              player.on("chordEnter",
                function(ev) {
                  console.log(ev);

                  /**
                  {
                    data: {
                      chord: Chord
                    }
                  }
                  */

                  /**
                  // 和音構造
                  type Chord = {
                    index: number,           // インデックス
                    startTime: number,       // 和音の開始位置（ミリ秒）
                    startTimeSec: number,    // 和音の開始位置（秒）
                    endTime: number,         // 和音の終了位置（ミリ秒）
                    endTimeSec: number,      // 和音の終了位置（秒）
                    durationTime: number,    // 和音の再生時間（ミリ秒）
                    durationTimeSec: number, // 和音の再生時間（秒）
                    name: string,            // 和音の名前
                    prev: Chord,             // 前の和音構造の参照
                    next: Chord              // 次の和音構造の参照
                  }
                  */
                });
            `
          }
        ]
      },
      {
        name: "chordLeave",
        contents: [
          {
            type: "text",
            content: "和音の終了で呼び出されるイベントです。"
          },
          {
            type: "code",
            content: `
              player.on("chordLeave",
                function(ev) {
                  console.log(ev);

                  /**
                  {
                    data: {
                      chord: Chord
                    }
                  }
                  */

                  /**
                  // 和音構造
                  type Chord = {
                    index: number,           // インデックス
                    startTime: number,       // 和音の開始位置（ミリ秒）
                    startTimeSec: number,    // 和音の開始位置（秒）
                    endTime: number,         // 和音の終了位置（ミリ秒）
                    endTimeSec: number,      // 和音の終了位置（秒）
                    durationTime: number,    // 和音の再生時間（ミリ秒）
                    durationTimeSec: number, // 和音の再生時間（秒）
                    name: string,            // 和音の名前
                    prev: Chord,             // 前の和音構造の参照
                    next: Chord              // 次の和音構造の参照
                  }
                  */
                });
            `
          }
        ]
      }
    ]
  };
}

const modules = PluginChordClassRef;

export default modules;
