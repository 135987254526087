//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import ClassRef from "./ClassRef";

/**
 * @class
 */
class WindowClassRef extends ClassRef
{
  static defaultProps = {
    name: "Window",
    type: "class",
    contents: [
      {
        type: "text",
        content: (
          <p>
            ここでは <code>window</code> グローバル変数から利用できる、<a href="//developer.mozilla.org/docs/Web/API/Window" target="_">ウィンドウインタフェース</a>について説明しています。<br/>
            ウェブブラウザから Songle JavaScript API を利用している場合、このウィンドウインタフェースが自動的に拡張されます。
          </p>
        )
      }
    ],
    constants: [
      {
        name: "Songle",
        type: "any",
        contents: [
          {
            type: "text",
            content: (
              <p>
                ウィンドウインタフェースに追加される Songle JavaScript API のクラスメソッドが定義されたオブジェクトです。<br/>
                このオブジェクトが確実に利用できることを保証する必要がある場合は <code>onSongleAPIReady</code> を利用することが推奨されます。
              </p>
            )
          },
          {
            type: "code",
            content: `
              const player =
                new window.Songle.Player({
                  mediaElement: "#songle"
                });

              player.on("ready",
                function(ev) {
                  player.play();
                });

              player.useMedia("https://www.youtube.com/watch?v=6j7VwJ7sM_k");
            `
          }
        ]
      }
    ],
    staticMethods: [
      {
        name: "onSongleAPIReady",
        type: "function",
        contents: [
          {
            type: "text",
            content: (
              <p>
                ウェブブラウザから Songle JavaScript API を利用している場合 Songle JavaScript API の初期化処理が完了すると自動的に呼び出される、予約されたメソッドです。<br/>
                このメソッドは Songle JavaScript API 読み込み前に定義しておく必要があります。
              </p>
            )
          },
          {
            type: "code",
            content: `
              window.onSongleAPIReady =
                function(Songle) {
                  const player =
                    new Songle.Player({
                      mediaElement: "#songle"
                    });

                  player.on("ready",
                    function(ev) {
                      player.play();
                    });

                  player.useMedia("https://www.youtube.com/watch?v=6j7VwJ7sM_k");
                }
            `
          }
        ]
      }
    ]
  };
}

const modules = WindowClassRef;

export default modules;
