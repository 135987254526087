//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Table } from "semantic-ui-react";

import ArticleTemplate from "../../../App/components/AppPage/ArticleTemplate";

/**
 * @class
 */
class Credit extends ArticleTemplate
{
  static defaultProps = {
    articles: [
      {
        type: "text",
        anchor: "section5",
        titleAs: "h2",
        titleEn: "Credit",
        titleJa: "クレジット",
        contentEn: (
          <p>
            Songle Sync is publicly available as an academic demonstration experiment.
            It is part of the research and development effort by National Institute of Advanced Industrial Science and Technology (AIST) and is partly supported by the JST ACCEL Grant Number JPMJAC1602, Japan (OngaACCEL Project).
          </p>
        ),
        contentJa: (
          <p>
            本システムは、国立研究開発法人産業技術総合研究所（産総研）における研究開発の一貫として、学術目的で一般公開・実証実験をしています。本研究は JST ACCEL OngaACCEL プロジェクトの支援を受けています。
          </p>
        )
      },
      {
        type: "text",
        titleAs: "h3",
        titleEn: "Research and Development Team",
        titleJa: "プロジェクトメンバ",
        contentEn: (
          <Table basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ width: "50%" }}>
                </Table.HeaderCell>
                <Table.HeaderCell style={{ width: "50%" }}>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  Masataka Goto
                </Table.Cell>
                <Table.Cell>
                  Direction, conception, music understanding technologies of Songle, planning of demo experiments
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Takahiro Inoue
                </Table.Cell>
                <Table.Cell>
                  Design and development of Songle API, demo experiments
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Jun Kato
                </Table.Cell>
                <Table.Cell>
                  Development of Songle API tutorials, demo applications, planning of demo experiments
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Keisuke Ishida
                </Table.Cell>
                <Table.Cell>
                  Development of Songle API demo applications
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Masahiro Hamasaki
                </Table.Cell>
                <Table.Cell>
                  Terms of use and privacy policy
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Kento Watanabe
                </Table.Cell>
                <Table.Cell>
                  Demo experiments
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Shima Kasai
                </Table.Cell>
                <Table.Cell>
                  Demo experiments
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Yuta Kawasaki
                </Table.Cell>
                <Table.Cell>
                  Development of Songle, demo experiments
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        ),
        contentJa: (
          <Table basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ width: "50%" }}>
                </Table.HeaderCell>
                <Table.HeaderCell style={{ width: "50%" }}>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  後藤 真孝　
                </Table.Cell>
                <Table.Cell>
                  統括・着想・音楽理解エンジン・実証実験企画
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  井上 隆広　
                </Table.Cell>
                <Table.Cell>
                  設計・開発・実証実験
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  加藤 淳　　
                </Table.Cell>
                <Table.Cell>
                  チュートリアル・実証実験企画
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  石田 啓介　
                </Table.Cell>
                <Table.Cell>
                  デモアプリ開発・実証実験
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  濱崎 雅弘　
                </Table.Cell>
                <Table.Cell>
                  利用規約・プライバシーポリシー
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  渡邉 研斗
                </Table.Cell>
                <Table.Cell>
                  実証実験
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  笠井 志麻
                </Table.Cell>
                <Table.Cell>
                  実証実験
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  川崎 裕太　
                </Table.Cell>
                <Table.Cell>
                  Songle 開発・実証実験
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )
      },
      {
        type: "text",
        titleAs: "h3",
        titleEn: "Ex-Members",
        titleJa: "元プロジェクトメンバ",
        contentEn: (
          <Table basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ width: "50%" }}>
                </Table.HeaderCell>
                <Table.HeaderCell style={{ width: "50%" }}>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  Masa Ogata
                </Table.Cell>
                <Table.Cell>
                  Development of initial prototype applications
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Rie Tanaka
                </Table.Cell>
                <Table.Cell>
                  Design of panels displayed with initial prototypes
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Shuhei Tsuchida
                </Table.Cell>
                <Table.Cell>
                  Development of initial prototype applications
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Hiromi Nakamura　
                </Table.Cell>
                <Table.Cell>
                  Development of initial prototype applications
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Jordan Smith　
                </Table.Cell>
                <Table.Cell>
                  Music understanding technology of Songle
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        ),
        contentJa: (
          <Table basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ width: "50%" }}>
                </Table.HeaderCell>
                <Table.HeaderCell style={{ width: "50%" }}>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  尾形 正泰　
                </Table.Cell>
                <Table.Cell>
                  報道発表時デモアプリ開発
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  田中 利枝
                </Table.Cell>
                <Table.Cell>
                  報道発表時パネルデザイン
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  土田 修平　
                </Table.Cell>
                <Table.Cell>
                  報道発表時デモアプリ開発
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  中村 裕美　
                </Table.Cell>
                <Table.Cell>
                  報道発表時デモアプリ開発
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Jordan Smith　
                </Table.Cell>
                <Table.Cell>
                  音楽理解エンジン
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )
      },
      {
        type: "text",
        titleAs: "h3",
        titleEn: "Legal advice (Kotto Dori Law Office)",
        titleJa: "法務アドバイザ（骨董通り法律事務所）",
        content: (
          <Table basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ width: "50%" }}>
                </Table.HeaderCell>
                <Table.HeaderCell style={{ width: "50%" }}>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  Kensaku Fukui
                </Table.Cell>
                <Table.Cell>
                  Legal advice
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Ayuko Hashimoto
                </Table.Cell>
                <Table.Cell>
                  Legal advice
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        ),
        contentJa: (
          <Table basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ width: "50%" }}>
                </Table.HeaderCell>
                <Table.HeaderCell style={{ width: "50%" }}>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  福井 健策
                </Table.Cell>
                <Table.Cell>
                  法務アドバイス
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  橋本 阿友子
                </Table.Cell>
                <Table.Cell>
                  法務アドバイス
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )
      },
      {
        type: "text",
        titleAs: "h3",
        titleEn: "Contact us",
        titleJa: "お問い合わせ",
        contentEn: (
          <p>
            Please reach us by sending an email to <a href="mailto:songle-ml [at] aist.go.jp">Songle API project</a>.
          </p>
        ),
        contentJa: (
          <p>
            お問い合わせは AIST Songle API project <a href="mailto:songle-ml [at] aist.go.jp">songle-ml [at] aist.go.jp</a> までご連絡ください。
          </p>
        )
      }
    ]
  }
}

const modules = Credit;

export default modules;
