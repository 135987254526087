//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Button, Form, Grid, Header, Input, TextArea } from "semantic-ui-react";

/**
 * @class
 */
class UserAppForm extends React.Component
{
  /**
   * @constructor
   */
  constructor(
    props
  )
  {
    super(props);

    const { app } = this.props;

    this.state = {
      formBody:
        !app ?
          {
          }
        :
          {
            name: app.attributes.name,
            description: app.attributes.description,
            url: app.attributes.url,
            permittedAddress: app.attributes.permittedAddress,
            permittedReferer: app.attributes.permittedReferer
          }
    };
  }

  /**
   * @function
   */
  createApp = (params) => {
    return new Promise((ready, error) => {
      const request =
        fetch("/api/v2/apps.json", {
          method: "POST",
          headers: ReactOnRails.authenticityHeaders({
            "Content-Type": "application/json"
          }),
          body: JSON.stringify({
            app: params,
            syntax: "camelcase"
          })
        });

      request
        .then((response) => response.json())
        .then((response) => {
          if(response.meta.status != 200)
          {
            error(response);
          }
          else
          {
            ready(response);
          }
        });
    });
  }

  /**
   * @function
   */
  updateApp = (params) => {
    const { app } = this.props;

    return new Promise((ready, error) => {
      const request =
        fetch(`/api/v2/apps/${app.id}.json`, {
          method: "PUT",
          headers: ReactOnRails.authenticityHeaders({
            "Content-Type": "application/json"
          }),
          body: JSON.stringify({
            app: params,
            syntax: "camelcase"
          })
        });

      request
        .then((response) => response.json())
        .then((response) => {
          if(response.meta.status != 200)
          {
            error(response);
          }
          else
          {
            ready(response);
          }
        });
    });
  }

  /**
   * @function
   */
  handleFormChange = (ev) => {
    const newBody = {};

    newBody[ ev.target.name ] = ev.target.value;

    this.setState({
      formBody: Object.assign(this.state.formBody, newBody)
    });
  }

  /**
   * @function
   */
  handleSubmit = (ev) => {
    const { app, onSubmit } = this.props;

    if(!app)
    {
      this.createApp(this.state.formBody)
        .then((response) => {
          onSubmit &&
          onSubmit(ev, { app: response.data });
        });
    }
    else
    {
      this.updateApp(this.state.formBody)
        .then((response) => {
          onSubmit &&
          onSubmit(ev, { app: response.data });
        });
    }
  }

  /**
   * @function
   * @override
   */
  render()
  {
    const { app } = this.props;

    return (
      <Form>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as="h4">
                名前（必須）
              </Header>
              <Input
                name="name"
                defaultValue={this.state.formBody.name}
                fluid
                onChange={this.handleFormChange} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as="h4">
                概要
              </Header>
              <TextArea
                name="description"
                row={4}
                defaultValue={this.state.formBody.description}
                onChange={this.handleFormChange} />
            </Grid.Column>
          </Grid.Row>
          {/*<Grid.Row>
            <Grid.Column>
              <Header as="h4">
                URL
              </Header>
              <Input
                name="url"
                defaultValue={this.state.formBody.url}
                fluid
                onChange={this.handleFormChange} />
            </Grid.Column>
          </Grid.Row>*/}
          <Grid.Row>
            <Grid.Column textAlign="right">
              <Button
                color="pink"
                onClick={this.handleSubmit}>
                送信
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

const modules = UserAppForm;

export default modules;
