//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { Button, Grid, Icon, Pagination } from "semantic-ui-react";

import UserApp from "../UserApp";

/**
 * @class
 */
class UserAppExplorer extends React.Component
{
  /**
   * @constructor
   */
  constructor(
    props
  )
  {
    super(props);

    this.state = {
      createFormIsVisible: false,
      totalPages: 1,
      activePage: 1,
      apps: []
    };

    this.updatePage();
  }

  /**
   * @function
   */
  updatePage = (params = {}) => {
    let { page, per_page, syntax } = params;

    page     = page     || 1;
    per_page = per_page || 1;
    syntax   = syntax   || "camelcase";

    return new Promise((ready, error) => {
      const queries = [];

      queries.push("page"     + "=" + page);
      queries.push("per_page" + "=" + per_page);
      queries.push("syntax"   + "=" + syntax);

      const request =
        fetch(`/api/v2/apps.json?${queries.join("&")}`);

      request
        .then((response) => response.json())
        .then((response) => {
          if(response.meta.status != 200)
          {
            error(response);
          }
          else
          {
            this.setState({
              totalPages: response.meta.totalPages,
              activePage: response.meta.activePage,
              apps: response.data
            });

            ready(response);
          }
        });
    });
  }

  /**
   * @function
   */
  handleCreateFormVisibleStateChange = () => {
    this.setState({
      createFormIsVisible: true
    });
  }

  /**
   * @function
   */
  handlePageChange = (ev, { activePage }) => {
    this.updatePage({
      page: activePage
    });
  }

  /**
   * @function
   */
  handleUserAppCreate = () => {
    this.setState({
      createFormIsVisible: false
    });

    this.updatePage();
  }

  /**
   * @function
   */
  handleUserAppUpdate = () => {
    this.setState({
      createFormIsVisible: false
    });

    this.updatePage({
      page: this.state.activePage
    })
  }

  /**
   * @function
   */
  handleUserAppDelete = () => {
    this.setState({
      createFormIsVisible: false
    });

    this.updatePage();
  }

  /**
   * @function
   * @override
   */
  render()
  {
    return (
      <Grid>
        <Grid.Row>
        {
          (() => {
            const { createFormIsVisible } = this.state;

            return (
              createFormIsVisible ?
                <Grid.Column>
                  <UserApp.Form onSubmit={this.handleUserAppCreate} />
                </Grid.Column>
              :
                <Grid.Column textAlign="right">
                  <Button
                    color="pink"
                    onClick={this.handleCreateFormVisibleStateChange}>
                    <Icon name="plus" />
                    アプリの作成
                  </Button>
                </Grid.Column>
            );
          })()
        }
        </Grid.Row>
        {
          (() => {
            if(this.state.totalPages < 2) {
              return;
            }

            return (
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Pagination
                    size="mini"
                    totalPages={this.state.totalPages}
                    activePage={this.state.activePage}
                    onPageChange={this.handlePageChange} />
                </Grid.Column>
              </Grid.Row>
            );
          })()
        }
        {
          (() => {
            if(this.state.apps.length < 1) {
              return;
            }

            return (
              <Grid.Row>
                <Grid.Column>
                {
                  this.state.apps.map((app) => {
                    return <UserApp.Content
                      key={app.attributes.token}
                      app={app}
                      onUpdate={this.handleUserAppUpdate}
                      onDelete={this.handleUserAppDelete} />
                  })
                }
                </Grid.Column>
              </Grid.Row>
            );
          })()
        }
        {
          (() => {
            if(this.state.totalPages < 2) {
              return;
            }

            return (
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Pagination
                    size="mini"
                    totalPages={this.state.totalPages}
                    activePage={this.state.activePage}
                    onPageChange={this.handlePageChange} />
                </Grid.Column>
              </Grid.Row>
            );
          })()
        }
      </Grid>
    );
  }
}

const modules = UserAppExplorer;

export default modules;
