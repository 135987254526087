//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import ClassRef from "./ClassRef";

/**
 * @class
 */
class SonglePluginSongleSyncClassRef extends ClassRef
{
  static defaultProps = {
    name: "Plugin.SongleSync",
    type: "class",
    properties: [
      {
        name: "message",
        type: "string"
      }
    ],
    getterProperties: [
      {
        name: "isMaster",
        type: "boolean",
      },
      {
        name: "isSlave",
        type: "boolean",
      }
    ],
    methods: [
      {
        name: "play",
        type: "void",
      },
      {
        name: "pause",
        type: "void",
      },
      {
        name: "stop",
        type: "void",
      },
      {
        name: "seekTo",
        type: "void",
      }
    ],
    events: [
      {
        name: "songleSyncMediaChange",
      },
      {
        name: "songleSyncMessage",
      },
      {
        name: "songleSyncNodeChange",
      },
      {
        name: "songleSyncNodeEnter",
      },
      {
        name: "songleSyncNodeLeave",
      },
      {
        name: "songleSyncStateChange",
      }
    ]
  };
}

const modules = SonglePluginSongleSyncClassRef;

export default modules;
