//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { List } from "semantic-ui-react";

import ArticleTemplate from "../../../App/components/AppPage/ArticleTemplate";

/**
 * @class
 */
class Abstract extends ArticleTemplate
{
  static defaultProps = {
    articles: [
      {
        type: "text",
        anchor: "section1",
        titleAs: "h2",
        title: "はじめに",
        content: (
          <p>
            このクイックスタートは、プログラミング言語 JavaScript を対象としたものです。<br/>
            API の利用を開始するためには Songle API モジュールをウェブサイトまたはシステムにインストールする必要があります。
          </p>
        )
      },
      {
        type: "text",
        titleAs: "h3",
        title: "動作要件",
        content: (
          <p>
            Songle API モジュールは、次の環境で動作することが確認されています。
          </p>
        )
      },
      {
        type: "text",
        titleAs: "h4",
        title: "ウェブブラウザ",
        content: (
          <List style={{ marginLeft: "24px" }}>
            <List.Item
              icon="microsoft edge"
              content="Microsoft Edge 18" />
            <List.Item
              icon="firefox"
              content="Mozilla Firefox 68" />
            <List.Item
              icon="chrome"
              content="Google Chrome 75" />
            <List.Item
              icon="safari"
              content="Safari 12.1" />
            <List.Item
              icon="opera"
              content="Opera 62" />
            <List.Item
              icon="safari"
              content="iOS Safari 12.2" />
            <List.Item
              icon="chrome"
              content="Chrome Android 75" />
          </List>
        )
      },
      {
        type: "text",
        titleAs: "h4",
        title: "Node.js",
        content: (
          <List style={{ marginLeft: "24px" }}>
            <List.Item
              icon="node js"
              content="Node.js 8.x" />
            <List.Item
              icon="node js"
              content="Node.js 9.x" />
            <List.Item
              icon="node js"
              content="Node.js 10.x" />
            <List.Item
              icon="node js"
              content="Node.js 11.x" />
            <List.Item
              icon="node js"
              content="Node.js 12.x" />
          </List>
        )
      },
      {
        type: "text",
        titleAs: "h3",
        title: "インストール",
        content: (
          <p>
            Songle API モジュールは script タグもしくは npm などのパッケージマネージャからインストールすることができます。
          </p>
        )
      },
      {
        type: "code",
        titleAs: "h4",
        title: "ウェブブラウザ",
        language: "html",
        content: `
          <script src="https://api.songle.jp/v2/api.js"></script>
        `
      },
      {
        type: "code",
        titleAs: "h4",
        title: "Node.js",
        language: "shell",
        content: `
          $ npm install songle-api
        `
      },
      {
        type: "text",
        titleAs: "h3",
        title: "Hello, Songle API !!",
        content: (
          <div>
            <p>
              ウェブブラウザから Songle API モジュールを利用した場合、その利用準備の完了を通知する onSongleAPIReady が Songle API モジュールによって自動的に呼び出されます。
            </p>
            <iframe
              src="//jsfiddle.net/gv3dhu4m/embedded/js,html,css,result"
              frameBorder="0"
              width="100%"
              height="150" />
          </div>
        )
      }
    ]
  }
}

const modules = Abstract;

export default modules;
