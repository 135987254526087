//@// vim: set filetype=javascript:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import { List, Segment } from "semantic-ui-react";

import AppPageTemplate from "../../App/components/AppPageTemplate";

import Article from "../components/TermsOfUsePage/Article";

/**
 * @class
 */
class TermsOfUsePage extends AppPageTemplate
{
  static defaultProps = {
    title: "利用規約",
    tocs: (
      <Segment className="square">
        <List size="small">
          <List.Item>
            <List.Icon name="list" />
            <List.Content>
              <List.Header>
                目次
              </List.Header>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.List>
                <List.Item
                  content="提供目的"
                  as="a"
                  href="#section1" />
                <List.Item
                  content="ユーザー"
                  as="a"
                  href="#section2" />
                <List.Item
                  content="サービスの利用"
                  as="a"
                  href="#section3" />
                <List.Item
                  content="ユーザーの遵守事項"
                  as="a"
                  href="#section4" />
                <List.Item
                  content="権利帰属"
                  as="a"
                  href="#section5" />
                <List.Item
                  content="ユーザーの同意事項"
                  as="a"
                  href="#section6" />
                <List.Item
                  content="クレジット"
                  as="a"
                  href="#section7" />
                <List.Item
                  content="非商用・商用利用"
                  as="a"
                  href="#section8" />
                <List.Item
                  content="禁止事項"
                  as="a"
                  href="#section9" />
                <List.Item
                  content="利用制限"
                  as="a"
                  href="#section10" />
                <List.Item
                  content="更新の義務"
                  as="a"
                  href="#section11" />
                <List.Item
                  content="責任の制限"
                  as="a"
                  href="#section12" />
                <List.Item
                  content="損害賠償等"
                  as="a"
                  href="#section13" />
                <List.Item
                  content="反社会的勢力の排除"
                  as="a"
                  href="#section14" />
                <List.Item
                  content="準拠法・裁判管轄"
                  as="a"
                  href="#section15" />
              </List.List>
            </List.Content>
          </List.Item>
        </List>
      </Segment>
    ),
    content: (
      <Segment className="square">
        <Article autoAnchor />
      </Segment>
    )
  };
}

const modules = TermsOfUsePage;

export default modules;
