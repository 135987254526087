//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import ClassRef from "./ClassRef";

/**
 * @class
 */
class PluginBeatClassRef extends ClassRef
{
  static defaultProps = {
    name: "Plugin.Beat",
    type: "class",
    contents: [
      {
        type: "text",
        content: "音楽地図の拍子を扱うプラグインクラスです。"
      },
      {
        type: "table",
        titleAs: "h3",
        title: "Constructor",
        subtitle: "1st arg options of the constructor",
        content: {
          headers: [
            {
              style: { width: "10%" },
              content: "名前"
            },
            {
              style: { width: "10%" },
              content: "型"
            },
            {
              style: { width: "10%" },
              content: "初期値"
            },
            {
              style: { width: "70%" },
              content: "説明"
            }
          ],
          bodies: [
            {
              cells: [
                {
                  content: (
                    <code>revisionId</code>
                  )
                },
                {
                  content: "number"
                },
                {
                  content: "0"
                },
                {
                  content: "使用する音楽地図のリビジョン番号です。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>offset</code>
                  )
                },
                {
                  content: "number"
                },
                {
                  content: "0"
                },
                {
                  content: "イベントのオフセット値（ミリ秒）です。"
                }
              ]
            },
            {
              cells: [
                {
                  content: (
                    <code>pollingInterval</code>
                  )
                },
                {
                  content: "number"
                },
                {
                  content: "50"
                },
                {
                  content: "イベントのポーリング値（ミリ秒）です。"
                }
              ]
            }
          ]
        }
      },
      {
        type: "code",
        content: `
          player.addPlugin(new Songle.Plugin.Beat());

          player.on("beatEnter",
            function(ev) {
              // do someting ...
            });
        `
      },
      {
        title: "リビジョンについて",
        type: "note",
        content: (
          <p>
            リビジョンとは、音楽理解技術による自動解析の誤りを人間が訂正した際に残る編集履歴のことです。この番号を指定することで、アプリケーションで使用する音楽地図の訂正状態を固定することができます。これは、音楽地図が第三者により編集された場合に発生する不整合を防ぐ場合に役立ちます。リビジョン番号は、<a href="//songle.jp/songs/610565" target="_">楽曲ページ</a>の<a href="//songle.jp/songs/610565/history" target="_">編集履歴</a>より確認することができます。
          </p>
        )
      },
      {
        title: "オフセットについて",
        type: "note",
        content: (
          <p>
            オフセットでは、イベントの実行タイミングにオフセットを与えることができます。これは、アプリケーションの処理負荷などの要因によって、イベントの実行タイミングが前後にズレる場合に役立ちます。また、オフセットはイベント毎に個別に与えることもできます。
          </p>
        )
      },
      {
        type: "code",
        content: `
          player.addPlugin(new Songle.Plugin.Beat({
            offset: -100,
          });

          player.on("beatEnter",
            function(ev) {
              // this event will be run faster 100ms than the original beat event
            });

          player.on("beatEnter",
            function(ev) {
              // this event will be run faster 200ms than the original beat event
            }, { offset: -100 });

          player.on("beatEnter",
            function(ev) {
              // this event will be run faster 300ms than the original beat event
            }, { offset: -200 });
        `
      }
    ],
    constants: [
      {
        name: "BarEnterTrapName",
        type: "string",
        contents: [
          {
            type: "text",
            content: "小節の開始で呼び出されるイベント名の定数です。"
          }
        ]
      },
      {
        name: "BarLeaveTrapName",
        type: "string",
        contents: [
          {
            type: "text",
            content: "小節の終了で呼び出されるイベント名の定数です。"
          }
        ]
      },
      {
        name: "BeatEnterTrapName",
        type: "string",
        contents: [
          {
            type: "text",
            content: "拍子の開始で呼び出されるイベント名の定数です。"
          }
        ]
      },
      {
        name: "BeatLeaveTrapName",
        type: "string",
        contents: [
          {
            type: "text",
            content: "拍子の終了で呼び出されるイベント名の定数です。"
          }
        ]
      }
    ],
    properties: [
      {
        name: "offset",
        type: "number",
        contents: [
          {
            type: "text",
            content: "イベントのオフセット値（ミリ秒）です。"
          }
        ]
      },
      {
        name: "pollingInterval",
        type: "number",
        contents: [
          {
            type: "text",
            content: "イベントのポーリング値（ミリ秒）です。"
          }
        ]
      }
    ],
    getterProperties: [
      {
        name: "revisionId",
        type: "number",
        contents: [
          {
            type: "text",
            content: "使用する音楽地図のリビジョン番号です。"
          }
        ]
      }
    ],
    methods: [
      {
        name: "seekToPrevBar",
        type: "void",
        contents: [
          {
            type: "text",
            content: (
              <p>
                現在の再生位置から後方に対して、最も近い小節にシークします。<br/>
                このメソッドは <code>Player</code> クラスに同名のメソッド名がプラグイン読み込み前に定義されていない場合は <code>Player</code> クラスから呼び出すことができます。
              </p>
            )
          },
          {
            type: "code",
            content: `
              player.addPlugin(new Songle.Plugin.Beat());

              player.on("ready",
                function() {
                  player.seekToPrevBar();
                });
            `
          }
        ]
      },
      {
        name: "seekToNextBar",
        type: "void",
        contents: [
          {
            type: "text",
            content: (
              <p>
                現在の再生位置から前方に対して、最も近い小節にシークします。<br/>
                このメソッドは <code>Player</code> クラスに同名のメソッド名がプラグイン読み込み前に定義されていない場合は <code>Player</code> クラスから呼び出すことができます。
              </p>
            )
          },
          {
            type: "code",
            content: `
              player.addPlugin(new Songle.Plugin.Beat());

              player.on("ready",
                function() {
                  player.seekToNextBar();
                });
            `
          }
        ]
      }
    ],
    events: [
      {
        name: "barEnter",
        contents: [
          {
            type: "text",
            content: "小節の開始で呼び出されるイベントです。"
          },
          {
            type: "code",
            content: `
              player.on("barEnter",
                function(ev) {
                  console.log(ev);

                  /**
                  {
                    data: {
                      beat: Beat
                    }
                  }
                  */

                  /**
                  // 拍子構造
                  type Beat = {
                    index: number,               // インデックス
                    startTime: number,           // 拍子の開始位置（ミリ秒）
                    startTimeSec: number,        // 拍子の開始位置（秒）
                    numberOfBeatsPerBar: number, // 現在の小節の拍子の数
                    number: number,              // 現在の小節の拍子の位置
                    bpm: numbeer,                // BPM
                    prev: Beat,                  // 前の拍子構造の参照
                    next: Beat                   // 次の拍子構造の参照
                  }
                  */
                });
            `
          }
        ]
      },
      {
        name: "barLeave",
        contents: [
          {
            type: "text",
            content: "小節の終了で呼び出されるイベントです。"
          },
          {
            type: "code",
            content: `
              player.on("barLeave",
                function(ev) {
                  console.log(ev);

                  /**
                  {
                    data: {
                      beat: Beat
                    }
                  }
                  */

                  /**
                  // 拍子構造
                  type Beat = {
                    index: number,               // インデックス
                    startTime: number,           // 拍子の開始位置（ミリ秒）
                    startTimeSec: number,        // 拍子の開始位置（秒）
                    numberOfBeatsPerBar: number, // 現在の小節の拍子の数
                    number: number,              // 現在の小節の拍子の位置
                    bpm: numbeer,                // BPM
                    prev: Beat,                  // 前の拍子構造の参照
                    next: Beat                   // 次の拍子構造の参照
                  }
                  */
                });
            `
          }
        ]
      },
      {
        name: "beatEnter",
        contents: [
          {
            type: "text",
            content: "拍子の開始で呼び出されるイベントです。"
          },
          {
            type: "code",
            content: `
              player.on("beatEnter",
                function(ev) {
                  console.log(ev);

                  /**
                  {
                    data: {
                      beat: Beat
                    }
                  }
                  */

                  /**
                  // 拍子構造
                  type Beat = {
                    index: number,               // インデックス
                    startTime: number,           // 拍子の開始位置（ミリ秒）
                    startTimeSec: number,        // 拍子の開始位置（秒）
                    numberOfBeatsPerBar: number, // 現在の小節の拍子の数
                    number: number,              // 現在の小節の拍子の位置
                    bpm: numbeer,                // BPM
                    prev: Beat,                  // 前の拍子構造の参照
                    next: Beat                   // 次の拍子構造の参照
                  }
                  */
                });
            `
          }
        ]
      },
      {
        name: "beatLeave",
        contents: [
          {
            type: "text",
            content: "拍子の終了で呼び出されるイベントです。"
          },
          {
            type: "code",
            content: `
              player.on("beatLeave",
                function(ev) {
                  console.log(ev);

                  /**
                  {
                    data: {
                      beat: Beat
                    }
                  }
                  */

                  /**
                  // 拍子構造
                  type Beat = {
                    index: number,               // インデックス
                    startTime: number,           // 拍子の開始位置（ミリ秒）
                    startTimeSec: number,        // 拍子の開始位置（秒）
                    numberOfBeatsPerBar: number, // 現在の小節の拍子の数
                    number: number,              // 現在の小節の拍子の位置
                    bpm: numbeer,                // BPM
                    prev: Beat,                  // 前の拍子構造の参照
                    next: Beat                   // 次の拍子構造の参照
                  }
                  */
                });
            `
          }
        ]
      }
    ]
  };
}

const modules = PluginBeatClassRef;

export default modules;
