//@// vim: set filetype=javascript wrap:
/**
 * @author Takahiro INOUE <takahiro.inoue@aist.go.jp>
 * @license (C) 2014-2019 AIST
 */
import React from "react";

import ClassRef from "./ClassRef";

/**
 * @class
 */
class MediaSuperClassRef extends ClassRef
{
  static defaultProps = {
    name: "Media.SuperClass",
    type: "abstract class",
    contents: [
      {
        type: "text",
        content: (
          <p>
            メディアクラスのスーパークラスです。<br/>
            全てのメディアクラスは、このスーパークラスのメソッド・プロパティを呼び出すことができます。
          </p>
        )
      },
      {
        type: "note",
        title: "メディアクラスの役割",
        content: (
          <p>
            メディアクラスは、音源となる様々な配信元の音楽プレイヤーを抽象化する役割を担っています。例えば、再生や停止、音量の調節など、使用する音楽プレイヤー毎にその利用方法が異なりますが、このクラスを利用することで、その差異を意識することなく制御することができるようになります。
          </p>
        )
      }
    ],
    constants: [
      {
        name: "Name",
        type: "string",
        contents: [
          {
            type: "text",
            content: (
              <p>
                メディアクラスに設定された名称の定数です。<br/>
                この値は継承先のメディアクラスによって変化することがあります。
              </p>
            )
          }
        ]
      },
      {
        name: "Type",
        type: "string",
        contents: [
          {
            type: "text",
            content: (
              <p>
                メディアクラスに設定された種類の定数です。<br/>
                この値は継承先のメディアクラスによって変化することがあります。
              </p>
            )
          }
        ]
      }
    ],
    getterProperties: [
      {
        name: "dispatcherName",
        type: "string",
        contents: [
          {
            type: "text",
            content: (
              <p>
                メディアクラスのインスタンスに設定された名称です。<br/>
                この値は継承先のメディアクラスによって変化することがあります。
              </p>
            )
          }
        ]
      },
      {
        name: "dispatcherType",
        type: "string",
        contents: [
          {
            type: "text",
            content: (
              <p>
                メディアクラスのインスタンスに設定された種類です。<br/>
                この値は継承先のメディアクラスによって変化することがあります。
              </p>
            )
          }
        ]
      },
      {
        name: "name",
        type: "string",
        contents: [
          {
            type: "text",
            content: "メディアの楽曲名です。"
          }
        ]
      },
      {
        name: "artistId",
        type: "number",
        contents: [
          {
            type: "text",
            content: "メディアのアーティストIDです。"
          }
        ]
      },
      {
        name: "artistName",
        type: "string",
        contents: [
          {
            type: "text",
            content: "メディアのアーティスト名です。"
          }
        ]
      },
      {
        name: "medianSeekTime",
        type: "number",
        contents: [
          {
            type: "text",
            content: "メディアのシーク処理にかかった時間（ミリ秒）の中央値です。"
          }
        ]
      },
      {
        name: "originalDuration",
        type: "number",
        contents: [
          {
            type: "text",
            content: (
              <p>
                メディアの再生時間（ミリ秒）です。<br/>
                この値は、メディアの種類（実装の差異）及び再生環境によって値が揺らぐため、特別な理由がなければ <a href="#Player.duration"><code>Player.duration</code></a> を使うことを推奨します。
              </p>
            )
          }
        ]
      },
      {
        name: "originalPosition",
        type: "number",
        contents: [
          {
            type: "text",
            content: (
              <p>
                メディアの再生位置（ミリ秒）です。<br/>
                この値は、メディアの種類（実装の差異）及び再生環境によって値が揺らぐため、特別な理由がなければ <a href="#Player.position"><code>Player.position</code></a> を使うことを推奨します。
              </p>
            )
          }
        ]
      }
    ]
  };
}

const modules = MediaSuperClassRef;

export default modules;
